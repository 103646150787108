import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { getCrumbs } from "../_helpers/crumbs";
import { companyList } from "App/Owner/_graphql/queries/company";
import ImportCard from "./_components/ImportCard/ImportCard";
import StandardImport from "./_imports/StandardImport/StandardImport";
import AddressImport from "./_imports/AddressImport/AddressImport";
import "./Import.scss";

function Import(props) {
  const client = useApolloClient();
  const [companies, setCompanies] = useState(true);
  const [importType, setImportType] = useState(null);

  // Load Payments
  const getCompanies = async (newShowPartners) => {
    const queryData = await client.query({
      variables: { showPartners: false },
      query: companyList,
    });
    setCompanies(queryData?.data?.ownerCompanies);
  };

  useEffect(() => {
    props?.setCurrentCount(0);
    props.setCrumbs(getCrumbs("import"));
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const importProps = {
    companies: companies,
  };

  return (
    <div className="admin-import">
      {importType === "standard" ? (
        <StandardImport
          removeModal={() => setImportType(false)}
          {...importProps}
        />
      ) : null}
      {importType === "address" ? (
        <AddressImport
          removeModal={() => setImportType(false)}
          {...importProps}
        />
      ) : null}
      <div className="imp-options">
        <ImportCard
          info={{
            title: "Standard Import",
            description:
              "This standard import takes in a CSV and applies it to the selected company.  The data imported includes:  Customer information, Bank information, and generates a Payment history with the info provided.",
          }}
          from={["forth"]}
          importAction={() => setImportType("standard")}
        />
        <ImportCard
          info={{
            title: "Address Import",
            description:
              "This address import takes in a CSV and applies it to the selected company.  The data imported includes:  Creditor and mailing information for the company to send correspondence.",
          }}
          from={["forth"]}
          importAction={() => setImportType("address")}
        />
      </div>
    </div>
  );
}

export default Import;
