export const getSelectedKeys = (path, tasksOpen, isModifying) => {
  if (tasksOpen) return ["tasks"];
  let currentPage = ["leads"];
  if (path === "/") currentPage = ["leads"];
  if (
    (path.includes("/customer") && !isModifying) ||
    path.includes("/customers")
  ) {
    currentPage = ["customers"];
  }
  if (path.includes("/customer/") && isModifying) {
    currentPage = ["leads"];
  }
  if (path === "/payments") currentPage = ["payments"];
  if (path.includes("/analytics")) currentPage = ["analytics"];
  if (path === "/users") currentPage = ["users"];
  if (path === "/partners") currentPage = ["partners"];
  if (path.includes("/wizard")) currentPage = [""];
  if (path.includes("/workflows")) currentPage = ["workflows"];
  if (path.includes("/tasks")) currentPage = ["tasks"];
  if (path.includes("/settings")) currentPage = ["settings"];
  return currentPage;
};
