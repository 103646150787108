import React, { useState, useEffect } from "react";
import { Tabs } from "_styleguide";
import { IconTag, IconCategory } from "@tabler/icons-react";
import TagTable from "./_components/TagTable/TagTable";
import CategoryTable from "./_components/CategoryTable/CategoryTable";
import "./Tags.scss";

function Tags(props) {
  const [tab, setTab] = useState("tags");

  const abstractProps = {
    ...props,
  };

  let items = [
    {
      key: "tags",
      label: (
        <div className="tab-name success">
          <IconTag />
          All Tags
        </div>
      ),
      children: <TagTable {...abstractProps} />,
    },
    {
      key: "categories",
      label: (
        <div className="tab-name warning">
          <IconCategory />
          Categories
        </div>
      ),
      children: <CategoryTable {...abstractProps} />,
    },
  ];

  useEffect(() => {
    props.setCrumbs({
      title: "Manage Tags",
      icon: <IconTag />,
      description:
        "Create and manage your custom tags & categories to apply to leads/customers/payments etc...",
    });
    props?.setCurrentCount(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="admin-tabs tag-tabs">
      <Tabs
        onChange={(key) => {
          setTab(key);
        }}
        activeKey={tab}
        items={items}
        className={tab}
        destroyInactiveTabPane={true}
      />
    </div>
  );
}

export default Tags;
