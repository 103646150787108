import { Button, Text } from "@centrate-io/barn";
import {
  IconScale,
  IconAddressBook,
  IconUsers,
  IconMap,
  IconPencil,
  IconTrash,
  IconBrandTelegram,
} from "@tabler/icons-react";
import { formatMoney, numberWithCommas } from "_assets/js/helpers";
import { TableActions } from "_styleguide";

export const getColumns = (
  showDrawer,
  setManageModal,
  deleteAddress,
  hoveredRowKey,
) => {
  return [
    {
      title: (
        <div className="column-name">
          <IconAddressBook />
          Name
        </div>
      ),
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (application, data) => (
        <div>
          <Text className="doc-name">
            <b>{data?.name}</b>
          </Text>
          <Text className="helper">
            <i>ID: {data?.short_id}</i>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconMap />
          Address
        </div>
      ),
      dataIndex: "address",
      key: "address",
      sorter: false,
      render: (address, data) => {
        const noAddress =
          !data.address &&
          !data.city &&
          !data.state &&
          !data.zip &&
          !data.email;
        return noAddress ? (
          <div>
            <Text>
              <i>n/a</i>
            </Text>
          </div>
        ) : (
          <div>
            <Text>
              {data.address} {data.address_line_two}
            </Text>
            <Text>
              {data.city}, {data.state} {data.zip}
            </Text>
          </div>
        );
      },
    },
    {
      title: (
        <div className="column-name">
          <IconBrandTelegram />
          Mail?
        </div>
      ),
      dataIndex: "allow_mail",
      key: "allow_mail",
      sorter: true,
      width: 120,
      align: "center",
      render: (allow_mail) => {
        return (
          <div>
            <Text>
              <b>{allow_mail ? "Yes" : "No"}</b>
            </Text>
          </div>
        );
      },
    },
    {
      title: (
        <div className="column-name">
          <IconUsers />
          Total Customers
        </div>
      ),
      dataIndex: "total_users",
      key: "total_users",
      sorter: false,
      width: 200,
      align: "center",
      render: (total_users) => (
        <Text>{numberWithCommas(total_users || 0)}</Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconScale />
          Total Debt
        </div>
      ),
      dataIndex: "total_debt",
      key: "total_debt",
      sorter: true,
      width: 200,
      align: "center",
      render: (total_debt) => <Text>{formatMoney(total_debt)}</Text>,
    },
    {
      title: <div className="column-name">&nbsp;</div>,
      dataIndex: "manage",
      key: "manage",
      width: 250,
      align: "center",
      fixed: "right",
      render: (address, data) => {
        const items = [
          {
            label: (
              <span>
                <IconPencil /> Manage Address
              </span>
            ),
            onClick: () => setManageModal(data.id),
          },
        ];
        items.push({
          label: (
            <span className="bad">
              <IconTrash /> Remove Address
            </span>
          ),
          onClick: () => deleteAddress(data.id),
        });

        return (
          <div className="table-actions">
            <Button
              type="secondary"
              size="small"
              className="boxed"
              onClick={() => showDrawer(data.id)}
            >
              Address Profile
            </Button>
            <TableActions items={items} />
          </div>
        );
      },
    },
  ];
};
