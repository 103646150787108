import { gql } from "@apollo/client";

export const saveAddressBook = gql`
  mutation saveAddressBook(
    $address_book_id: String
    $address_book: AddressBookInput!
  ) {
    saveAddressBook(
      address_book_id: $address_book_id
      address_book: $address_book
    )
  }
`;

export const removeAddress = gql`
  mutation removeAddress($address_book_id: String!) {
    removeAddress(address_book_id: $address_book_id)
  }
`;

export const saveAddressContact = gql`
  mutation saveAddressContact(
    $address_contact_id: String
    $address_book_id: String!
    $address_contact: AddressBookContactInput!
  ) {
    saveAddressContact(
      address_contact_id: $address_contact_id
      address_book_id: $address_book_id
      address_contact: $address_contact
    )
  }
`;

export const removeAddressContact = gql`
  mutation removeAddressContact($address_contact_id: String!) {
    removeAddressContact(address_contact_id: $address_contact_id)
  }
`;
