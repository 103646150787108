export const columnTypes = {
  name: "String",
  address: "String",
  address_line_two: "String",
  city: "String",
  state: "String",
  zip: "String",
  website: "String",
  phone: "String",
  email: "String",
};
