import React from "react";
import { useParams } from "react-router-dom";
import Menu from "./_components/Menu/Menu";
import Manage from "./Manage/Manage";
import Users from "./Users/Users";
import Partners from "./Partners/Partners";
import Teams from "./Teams/Teams";
import Tags from "./Tags/Tags";
import Triggers from "./Triggers/Triggers";
import Integrations from "./Integrations/Integrations";
import "./Settings.scss";

const DEFAULT_TAB = "manage";

function Settings(props) {
  const { tab } = useParams();

  const globalProps = {
    tab: tab || DEFAULT_TAB,
    ...props,
  };

  const needScroll = ["integrations"];

  const isPartner = props.user?.parent_company;

  return (
    <div className={`admin-settings`}>
      <Menu {...globalProps} />
      <div
        className={`settings-body ${needScroll.includes(globalProps.tab) ? "need-scroll" : ""}`}
      >
        {globalProps.tab === "manage" && <Manage {...globalProps} />}
        {globalProps.tab === "users" && <Users {...globalProps} />}
        {globalProps.tab === "partners" && !isPartner && (
          <Partners {...globalProps} />
        )}
        {globalProps.tab === "tags" && <Tags {...globalProps} />}
        {globalProps.tab === "triggers" && <Triggers {...globalProps} />}
        {globalProps.tab === "teams" && <Teams {...globalProps} />}
        {globalProps.tab === "integrations" && (
          <Integrations {...globalProps} />
        )}
      </div>
    </div>
  );
}

export default Settings;
