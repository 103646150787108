import { TableActions } from "_styleguide";
import { IconTrash, IconPencil } from "@tabler/icons-react";
import moment from "moment";

export const getColumns = (setManageModal, deleteCategory, tags, readOnly) => {
  return [
    {
      title: "Category",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (name, data) => {
        const tagCount = tags?.filter((t) => {
          return t.tag_category === data.name;
        })?.length;

        return (
          <div className="category-info">
            <div className="category-data">
              <h4>{data.name}</h4>
              <p>
                {tagCount || 0} Tag{tagCount === 1 ? "" : "s"}
                &nbsp;&nbsp;&bull;&nbsp;&nbsp; Updated{" "}
                {moment(data.updatedAt * 1).format("MMM D, YYYY")}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      title: <div className="column-name">&nbsp;</div>,
      dataIndex: "manage",
      key: "manage",
      sorter: false,
      width: 60,
      fixed: "right",
      render: (url, data) => {
        const items = [
          {
            label: (
              <span>
                <IconPencil /> Manage Category
              </span>
            ),
            onClick: () => setManageModal(data.id),
          },
        ];
        if (!readOnly) {
          items.push({
            label: (
              <span className="bad">
                <IconTrash /> Remove Category
              </span>
            ),
            onClick: () => deleteCategory(data.id),
          });
        }
        return <TableActions items={items} />;
      },
    },
  ];
};
