import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { getTagCategory } from "App/Admin/_graphql/queries/tag";
import { saveTagCategory } from "App/Admin/_graphql/mutations/tag";
import { getErrors } from "_helpers/api";
import { IconTag } from "@tabler/icons-react";
import { FormInput, InputLabel, Flex, Loading } from "_styleguide";
import "./ManageCategory.scss";

function ManageCategory(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [category, setCategory] = useState();

  const isEditing = props.tagCategoryId !== "new";

  const fetchCategory = async () => {
    if (isEditing) {
      const queryData = await client.query({
        variables: { tag_category_id: props?.tagCategoryId },
        query: getTagCategory,
      });
      setLoading(false);
      setCategory(queryData?.data?.getTagCategory);
    } else {
      setLoading(false);
      setCategory({
        name: undefined,
      });
    }
  };

  // Submit question form
  const saveForm = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          tag_category_id:
            props.tagCategoryId !== "new" ? props.tagCategoryId : null,
          ...category,
        },
        mutation: saveTagCategory,
      });
      message.success(
        `Category successfully ${isEditing ? "saved" : "created"}`,
      );
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(
        getErrors(err) ||
          `Error ${isEditing ? "saving" : "creating"} category, try again`,
      );
      setSaving(false);
    }
    setSaving(false);
  };

  const updateForm = (field, value) => {
    const editedform = { ...category };
    editedform[field] = value;
    setCategory(editedform);
  };

  useEffect(() => {
    fetchCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isValid = category?.name?.length > 0;

  return (
    <Modal
      wrapClassName="manage-category-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={loading ? true : false}
      width={456}
    >
      {loading ? (
        <Modal.Body className="body-loading">
          <Loading />
        </Modal.Body>
      ) : (
        <>
          <div className="pp-modal-header">
            <div>
              <IconTag />
            </div>
            <h3>{isEditing ? "Manage" : "Create"} Category</h3>
          </div>

          <Modal.Body>
            <Flex vertical={true} gap={16}>
              <Flex vertical={true}>
                <InputLabel htmlFor="name">Category Name *</InputLabel>
                <FormInput
                  id="name"
                  value={category.name}
                  disabled={saving}
                  onChange={(e) => updateForm("name", e.target.value)}
                  placeholder="e.g. Payments..."
                  size="medium"
                />
              </Flex>
            </Flex>
          </Modal.Body>

          <Modal.Actions>
            <Button
              disabled={saving}
              block
              type="secondary"
              onClick={() => setVisible(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={saveForm}
              loading={saving}
              block
              type="primary"
              disabled={!isValid}
            >
              {isEditing ? "Save" : "Create"} Category
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
}

export default ManageCategory;
