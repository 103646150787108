import React from "react";
import { Text } from "@centrate-io/barn";
import {
  IconAddressBook,
  IconMap,
  IconPencil,
  IconTrash,
  IconCalendar,
  IconBolt,
  IconChevronsRight,
  IconRun,
  IconTag,
} from "@tabler/icons-react";
import { TableActions, Flex, Tag, Toggle } from "_styleguide";
import moment from "moment";
import { triggerFriendly } from "../_modals/ManageTrigger/_components/SelectTrigger/_types";
import { friendlyAction } from "../_modals/ManageTrigger/_components/SelectActions/_types";
import { customMultiple } from "../_modals/ManageTrigger/_components/SelectTrigger/_custom";
import { SelectTags } from "_styleguide";

export const getColumns = (
  setManageModal,
  deleteTrigger,
  readOnly,
  options,
  toggleActive,
  tags,
) => {
  return [
    {
      title: (
        <div className="column-name">
          <IconAddressBook />
          Name
        </div>
      ),
      dataIndex: "name",
      key: "name",
      width: 200,
      sorter: true,
      render: (application, data) => (
        <div>
          <Text className="doc-name">
            <b>{data?.name}</b>
          </Text>
          <Text className="helper">
            <i>ID: {data?.short_id}</i>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconTag />
        </div>
      ),
      width: 80,
      dataIndex: "tags",
      hidden: false,
      key: "tags",
      render: (tags, data) => (
        <SelectTags category="triggers" triggerId={data.id} tags={tags} />
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconBolt />
          Trigger
        </div>
      ),
      dataIndex: "trigger_key",
      key: "trigger_key",
      sorter: true,
      width: 200,
      align: "center",
      render: (trigger_key, data) => {
        const multiple = data?.trigger_multiple || [];
        const multipleMap = customMultiple[data?.trigger_key];

        const multipleText = multiple.map((m) => {
          return multipleMap[m];
        });

        return (
          <Flex className="trigger-actions" align="center">
            {multipleText?.length > 0 ? (
              <div className="actions-list">
                {multipleText?.map((m, i) => {
                  return (
                    <Tag type="warning">
                      {triggerFriendly[data?.trigger_key]} ({m})
                    </Tag>
                  );
                })}
              </div>
            ) : (
              <Tag type="warning">{triggerFriendly[data?.trigger_key]}</Tag>
            )}
            <IconChevronsRight />
            <div className="actions-list">
              {data.actions?.map((a, i) => {
                return (
                  <React.Fragment key={`ak-${i}-${a?.action_key}`}>
                    {friendlyAction(a?.action_key, a?.action_ref_id, options)}
                  </React.Fragment>
                );
              })}
            </div>
          </Flex>
        );
      },
    },

    {
      title: (
        <div className="column-name">
          <IconRun />
          Runs
        </div>
      ),
      dataIndex: "run_count",
      key: "run_count",
      sorter: true,
      align: "center",
      width: 80,
      render: (run_count) => <Text>{run_count || 0}</Text>,
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Last Run
        </div>
      ),
      dataIndex: "last_run",
      key: "last_run",
      sorter: true,
      width: 150,
      render: (last_run) => (
        <Text>
          {last_run ? (
            moment(parseInt(last_run)).format("M/D/YYYY @ h:mm a")
          ) : (
            <i>n/a</i>
          )}
        </Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconMap />
          Active?
        </div>
      ),
      dataIndex: "is_active",
      key: "is_active",
      sorter: true,
      width: 80,
      align: "center",
      render: (is_active, data) => (
        <Toggle
          onClick={() => toggleActive(data.id, is_active)}
          checked={is_active}
        />
      ),
    },
    {
      title: <div className="column-name">&nbsp;</div>,
      dataIndex: "manage",
      key: "manage",
      sorter: false,
      width: 60,
      fixed: "right",
      render: (url, data) => {
        const items = [
          {
            label: (
              <span>
                <IconPencil /> Manage Trigger
              </span>
            ),
            onClick: () => setManageModal(data.id),
          },
        ];
        if (!readOnly) {
          items.push({
            label: (
              <span className="bad">
                <IconTrash /> Delete Trigger
              </span>
            ),
            onClick: () => deleteTrigger(data.id),
          });
        }
        return <TableActions items={items} />;
      },
    },
  ];
};
