import { useState } from "react";
import { Select } from "_styleguide";
import { triggerTypes, allTriggers } from "./_types";
import "./SelectTrigger.scss";

const RecursiveSelect = ({
  options,
  depth,
  steps,
  updateSteps,
  label,
  multiple,
  triggerMultiple,
  updateMultiple,
}) => {
  const handleChange = (value) => {
    const updatedSteps = steps.slice(0, depth);
    updatedSteps[depth] = value;
    updateSteps(updatedSteps);
  };

  const selectedValue = steps[depth];
  const selectedOption = options.find((opt) => opt.value === selectedValue);

  return (
    <>
      <div className="option-box">
        {label ? <div className="ob-label">{label}?</div> : null}
        {!multiple ? (
          <Select
            placeholder="Select an option"
            value={selectedValue}
            size="medium"
            onChange={handleChange}
            popupClassName="pp-select-dropdown"
          >
            {options.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        ) : null}
        {multiple ? (
          <Select
            mode="multiple"
            placeholder="Select an option"
            value={triggerMultiple}
            size="medium"
            onChange={(val) => updateMultiple("trigger_multiple", val)}
            popupClassName="pp-select-dropdown"
          >
            {options.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        ) : null}
      </div>

      {selectedOption?.next?.options && (
        <RecursiveSelect
          options={selectedOption.next.options}
          label={selectedOption.next.name}
          multiple={selectedOption.next.multiple}
          depth={depth + 1}
          steps={steps}
          updateSteps={updateSteps}
          triggerMultiple={triggerMultiple}
          updateMultiple={updateMultiple}
        />
      )}
    </>
  );
};

function SelectTrigger(props) {
  const [steps, setSteps] = useState([]);

  const checkUpdate = (newValue) => {
    const joinedVal = newValue.join("_");
    if (allTriggers.includes(joinedVal)) {
      props.updateForm({
        trigger_key: joinedVal,
        trigger_ref_id: undefined,
      });
    } else {
      props.updateForm({
        trigger_key: undefined,
        trigger_ref_id: undefined,
      });
    }
    setSteps(newValue);
  };

  return (
    <div className="select-trigger">
      <RecursiveSelect
        options={triggerTypes}
        depth={0}
        steps={steps}
        updateSteps={checkUpdate}
        updateMultiple={props.updateMultiple}
        triggerMultiple={props.form?.trigger_multiple || []}
      />
    </div>
  );
}

export default SelectTrigger;
