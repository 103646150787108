import { gql } from "@apollo/client";

export const saveTrigger = gql`
  mutation saveTrigger($trigger_id: String, $trigger: TriggerInput!) {
    saveTrigger(trigger_id: $trigger_id, trigger: $trigger)
  }
`;

export const toggleTrigger = gql`
  mutation toggleTrigger($trigger_id: String!) {
    toggleTrigger(trigger_id: $trigger_id)
  }
`;

export const removeTrigger = gql`
  mutation removeTrigger($trigger_id: String!) {
    removeTrigger(trigger_id: $trigger_id)
  }
`;
