import React, { useState, useEffect } from "react";
import { Table, Empty, Select } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { getColumns } from "./_data/Columns";
import { Button, message, Modal } from "@centrate-io/barn";
import ManageTag from "./_modals/ManageTag/ManageTag";
import { IconPlus } from "@tabler/icons-react";
import { useTags } from "_contexts/useTags";
import { getErrors } from "_helpers/api";
import { removeTag } from "App/Admin/_graphql/mutations/tag";
import "./TagTable.scss";

function TagTable(props) {
  const client = useApolloClient();
  const { tags, categories, reloadTags } = useTags();
  const [manageModal, setManageModal] = useState(false);
  const [filterCategory, setFilterCategory] = useState();
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    pagination: { pageSize: 10, showSizeChanger: false },
  });

  // Load Payments
  const fetchTags = async () => {
    await reloadTags();
    setState({
      loading: false,
    });
  };

  // Remove Bank Account
  const deleteTag = async (tagId) => {
    Modal.confirm({
      title: "Are you sure you want remove this Tag?",
      okText: "Remove Tag",
      content: (
        <p>
          <b style={{ fontWeight: 600, color: "#000" }}>Warning:</b>
          <br />
          This will REMOVE all tags with this key from all associated models as
          well as from the tags list like: Customers, Leads, Payments, etc.{" "}
          <br />
          <br />
          <b style={{ fontWeight: 600, color: "#000" }}>
            This cannot be undone!
          </b>
        </p>
      ),
      onOk: async () => {
        try {
          await client.mutate({
            variables: {
              tag_id: tagId,
            },
            mutation: removeTag,
          });
          message.success(`Tag successfully removed`);
          fetchTags();
        } catch (err) {
          console.log(err);
          message.error(getErrors(err) || "Error removing document, try again");
        }
      },
    });
  };

  useEffect(() => {
    fetchTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let tableData = (tags || []).filter((t) => {
    if (!filterCategory || filterCategory === "all") return true;
    return t.tag_category === filterCategory;
  });
  const columns = getColumns(setManageModal, deleteTag, props.readOnly);
  const isEmpty = (tags || [])?.length <= 0;

  return (
    <>
      {manageModal ? (
        <ManageTag
          tagId={manageModal}
          reload={fetchTags}
          categories={categories}
          removeModal={() => setManageModal(false)}
        />
      ) : null}
      {isEmpty ? (
        <Empty
          title="Create Your First Tag"
          description="Tags can help you organize and follow your most important items more efficiently..."
          action="Create Tag"
          actionIcon={<IconPlus />}
          onClick={() => setManageModal("new")}
        />
      ) : (
        <div className="settings-tags">
          <div className="settings-search">
            <div className="ms-filters">
              <Select
                value={filterCategory}
                onChange={(val) =>
                  setFilterCategory(val === "all" ? undefined : val)
                }
                className="filter-select"
                popupClassName="pp-popup"
                placeholder="All Categories"
                style={{ marginRight: "12px", width: "200px" }}
              >
                <Select.Option value="all">All Categories</Select.Option>
                {categories.map((c) => (
                  <Select.Option key={`tfilter-${c.id}`} value={c.name}>
                    {c.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="ms-actions">
              {!props.readOnly ? (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => setManageModal("new")}
                >
                  <IconPlus />
                  Create Tag
                </Button>
              ) : null}
            </div>
          </div>
          <Table
            columns={columns}
            loading={state.loading}
            pagination={state.pagination}
            rowKey={(record) => record.id}
            dataSource={tableData}
            showSorterTooltip={false}
            scroll={{
              y: "calc(80vh - 231px)",
              x: "max-content",
            }}
            locale={{
              emptyText: state.loading ? "Loading tags..." : "No tags found",
            }}
          />
        </div>
      )}
    </>
  );
}

export default TagTable;
