import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Drawer } from "antd";
import { Modal } from "@centrate-io/barn";
import { Loading } from "_styleguide";
import Navigation from "./_components/Navigation/Navigation";
import { getAddressBook } from "App/Admin/_graphql/queries/address_book";
import "./ManageBook.scss";

function ManageBook(props) {
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [addressBook, setAddressBook] = useState();

  const fetchAddressBook = async () => {
    const queryData = await client.query({
      variables: { address_book_id: props?.addressId },
      query: getAddressBook,
    });
    setLoading(false);
    setAddressBook(queryData?.data?.getAddressBook);
  };

  useEffect(() => {
    fetchAddressBook();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer
      width={640}
      onClose={props.onClose}
      open={props.open}
      maskClosable={false}
      className="ab-drawer"
      rootClassName="ab-parent"
      destroyOnClose={true}
    >
      {loading || !addressBook ? (
        <>
          <Loading />
          <Modal.Close onClick={props.onClose} />
        </>
      ) : (
        <div className="ab-body">
          <Modal.Close onClick={props.onClose} />

          <div className="ab-header">
            <div className="ab-info">
              <div className="ab-logo">{addressBook?.name?.[0] || "?"}</div>
              <div className="ab-name">
                <h2>{addressBook?.name}</h2>
                <p>ID: {addressBook?.short_id}</p>
              </div>
            </div>
            <div className="ab-contact">
              <div className="ab-box">
                <h5>Address</h5>
                <p>
                  {addressBook?.address} {addressBook?.address_line_two}
                  <br />
                  {addressBook?.city}, {addressBook?.state} {addressBook?.zip}
                </p>
              </div>
              <div className="ab-box">
                <h5>Information</h5>
                <p>
                  {addressBook?.phone ? (
                    <>
                      {addressBook?.phone}
                      <br />
                    </>
                  ) : null}
                  {addressBook?.email ? (
                    <>
                      {addressBook?.email}
                      <br />
                    </>
                  ) : null}
                  {addressBook?.website ? (
                    <>
                      {addressBook?.website}
                      <br />
                    </>
                  ) : null}
                </p>
              </div>
            </div>
          </div>
          <Navigation addressId={props.addressId} />
        </div>
      )}
    </Drawer>
  );
}

export default ManageBook;
