export const customMultiple = {
  PAYMENT_FAILED: {
    NSF: "Insufficient Funds",
    CLOSED: "Account Closed",
    INVALID: "Account Invalid",
    FROZEN: "Account Frozen",
    STOPPED: "Payment Stopped",
    UNAUTHORIZED: "Unauthorized",
  },
};
