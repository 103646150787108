import { Text } from "@centrate-io/barn";
import { IconPencil, IconTrash } from "@tabler/icons-react";
import { TableActions } from "_styleguide";

export const getColumns = (setManageModal, deleteContact, hoveredRowKey) => {
  return [
    {
      title: "Contact",
      dataIndex: "name",
      key: "name",
      render: (name, data) => (
        <div className="csmall">
          <Text>
            <b>{data?.name}</b>
          </Text>
          <Text>
            <p>{data?.organization || <span>&nbsp;</span>}</p>
          </Text>
        </div>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (address, data) => (
        <div className="csmall">
          <Text>
            <b>
              {data?.address || ""} {data?.address_line_two || ""},{" "}
              {data?.city || ""}, {data?.state || ""} {data?.zip || ""}
            </b>
          </Text>
          <Text>
            <p>
              {data?.phone || data?.email || data?.website || (
                <span>&nbsp;</span>
              )}
            </p>
          </Text>
        </div>
      ),
    },
    {
      title: <div className="column-name">&nbsp;</div>,
      dataIndex: "manage",
      key: "manage",
      sorter: false,
      width: 60,
      fixed: "right",
      render: (url, data) => {
        const items = [
          {
            label: (
              <span>
                <IconPencil /> Manage Contact
              </span>
            ),
            onClick: () => setManageModal(data.id),
          },
        ];
        items.push({
          label: (
            <span className="bad">
              <IconTrash /> Remove Contact
            </span>
          ),
          onClick: () => deleteContact(data.id),
        });

        return <TableActions items={items} />;
      },
    },
  ];
};
