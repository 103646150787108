import { gql } from "@apollo/client";

export const getThreads = gql`
  query getThreads($application_id: ID!) {
    getThreads(application_id: $application_id) {
      id
      ext_thread_id
      subject
      participants
      createdAt
      updatedAt
      unread

      latest_email {
        id
        body
        subject
        from {
          email
          name
        }
        user {
          id
          name
        }
      }
    }
  }
`;

export const getConversation = gql`
  query getConversation($application_id: ID!, $thread_id: ID!) {
    getConversation(application_id: $application_id, thread_id: $thread_id) {
      id
      body
      subject
      from {
        email
        name
      }
      to {
        email
        name
      }
      cc {
        email
        name
      }
      bcc {
        email
        name
      }
      user {
        id
        name
      }
      incoming
      ext_email_id
      ext_thread_id
      tracking {
        delivered
      }
      createdAt
      updatedAt
    }
  }
`;
