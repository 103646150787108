import { useState, useEffect } from "react";
import { BrowserRouter, redirect } from "react-router-dom";
import { getUser } from "_helpers/auth";
import Auth, { VerifyRoutes } from "App/Auth/Auth";
import Admin from "App/Admin/Admin";
import Owner from "App/Owner/Owner";
import Application from "App/Application/Application";
import Register from "App/Register/Register";
import { Construction } from "_styleguide";
import { updateLogin } from "_graphql/queries/user";
import { logOut } from "_helpers/auth";
import { message } from "@centrate-io/barn";

function App(props) {
  /* Manage Dark Mode */
  const startMode = localStorage.getItem("mode");
  const [darkMode, setDarkMode] = useState(startMode === "dark");
  const toggleDarkMode = () => {
    document.body.className = darkMode ? "barn-light" : "barn-dark";
    localStorage.setItem("mode", darkMode ? "light" : "dark");
    setDarkMode(!darkMode);
  };

  const [user, setUser] = useState(getUser());
  const login = (token) => {
    localStorage.setItem("JWT", token);
    setUser(getUser());
    redirect("/");
  };

  // Load Payments
  const fetchLogin = async () => {
    const queryData = await props.client.query({
      query: updateLogin,
    });
    const newToken = queryData?.data?.updateLogin;
    if (newToken) {
      localStorage.setItem("JWT", newToken);
      setUser(getUser());
    } else if (user && !newToken) {
      message.error("Access denied, reach out to your supervisor");
      setTimeout(logOut, 500);
    }
  };

  useEffect(() => {
    const mode = localStorage.getItem("mode");
    document.body.className = mode === "dark" ? "barn-dark" : "barn-light";
    setDarkMode(!mode || mode === "dark" ? true : false);
    if (user) fetchLogin();
    // eslint-disable-next-line
  }, []);

  const globalProps = {
    darkMode,
    toggleDarkMode,
    login,
    user,
    fetchLogin,
    client: props.client,
  };
  const pathSplit = window.location.pathname?.split("/");
  const ROLE_TYPE = process.env.REACT_APP_ROLE_TYPE;
  const SSO_DOMAINS =
    (process.env.REACT_APP_SSO_DOMAINS || "")?.split(",") || [];

  const hostname = window.location.hostname;
  const isApplication = SSO_DOMAINS.includes(hostname);
  const auth = <Auth {...globalProps} />;
  const main = <Application {...globalProps} />;
  const admin = <Admin {...globalProps} />;
  const owner = <Owner {...globalProps} />;
  const register = <Register {...globalProps} />;
  const verify = <VerifyRoutes {...globalProps} />;

  // console.log("SSO_DOMAINS", SSO_DOMAINS);
  // console.log("hostname", hostname);

  // Determine which routes to show based on user
  // This lets ensures security and disables access across types
  let routes = null;
  if (isApplication) {
    if (
      (pathSplit?.[1] === "r" ||
        pathSplit?.[1] === "s" ||
        pathSplit?.[1] === "b") &&
      pathSplit?.[2]?.length === 12
    ) {
      routes = register;
    } else {
      routes = main;
    }
    document.title = "PayPath | Apply";
  }
  if (ROLE_TYPE === "admin" && !isApplication) {
    routes = auth;
    if (
      (pathSplit?.[1] === "r" ||
        pathSplit?.[1] === "s" ||
        pathSplit?.[1] === "b") &&
      pathSplit?.[2]?.length === 12
    ) {
      routes = register;
    } else if (user && !user.two_factor && !user.masq) {
      routes = verify;
    } else if (user?.role === "super") {
      routes = owner;
    } else if (
      user?.role === "admin" ||
      user?.role === "manager" ||
      user?.role === "user"
    ) {
      routes = admin;
    }
    document.title = "PayPath | CRM";
  }
  if (ROLE_TYPE === "construction") {
    document.title = "PayPath | Maintenance";
    routes = null;
  }

  // console.log("userData", user);

  return routes ? <BrowserRouter>{routes}</BrowserRouter> : <Construction />;
}

export default App;
