import { gql } from "@apollo/client";

export const saveTag = gql`
  mutation saveTag(
    $tag_id: String
    $key: String!
    $background_color: String!
    $tag_category: String!
    $font_color: String!
    $tooltip: String
    $description: String!
  ) {
    saveTag(
      tag_id: $tag_id
      key: $key
      background_color: $background_color
      tag_category: $tag_category
      font_color: $font_color
      tooltip: $tooltip
      description: $description
    )
  }
`;

export const saveTagCategory = gql`
  mutation saveTagCategory($tag_category_id: String, $name: String!) {
    saveTagCategory(tag_category_id: $tag_category_id, name: $name)
  }
`;

export const applyTags = gql`
  mutation applyTags(
    $application_id: String
    $payment_id: String
    $trigger_id: String
    $tags: [String]
  ) {
    applyTags(
      application_id: $application_id
      payment_id: $payment_id
      trigger_id: $trigger_id
      tags: $tags
    )
  }
`;

export const removeTag = gql`
  mutation removeTag($tag_id: String!) {
    removeTag(tag_id: $tag_id)
  }
`;

export const removeTagCategory = gql`
  mutation removeTagCategory($tag_category_id: String!) {
    removeTagCategory(tag_category_id: $tag_category_id)
  }
`;
