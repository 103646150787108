import { useState } from "react";
import { Select, CustomTag } from "_styleguide";
import { actionTypes, allActions, isArray, requiresRef } from "./_types";
import { capitalize } from "_assets/js/helpers";
import { customOptions } from "./_custom";
import "./SelectActions.scss";

const RecursiveSelect = ({
  dynamicOptions,
  options,
  listOptions,
  depth,
  steps,
  updateSteps,
  updateOptions,
  label,
  refId,
  actionData,
}) => {
  const handleChange = (value) => {
    const updatedSteps = steps.slice(0, depth);
    updatedSteps[depth] = value;
    updateSteps(updatedSteps);
  };

  const selectedValue = steps[depth];
  const selectedOption = options.find((opt) => opt.value === selectedValue);

  return (
    <>
      <div className="option-box">
        {label ? <div className="ob-label">{label}?</div> : null}
        <Select
          placeholder="Select an option"
          value={selectedValue}
          size="medium"
          onChange={handleChange}
          popupClassName="pp-select-dropdown"
        >
          {options.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
      </div>

      {selectedOption?.next?.options &&
      isArray(selectedOption?.next?.options) ? (
        <RecursiveSelect
          dynamicOptions={dynamicOptions}
          options={selectedOption.next.options}
          listOptions={listOptions}
          label={selectedOption.next.name}
          depth={depth + 1}
          steps={steps}
          updateSteps={updateSteps}
          updateOptions={updateOptions}
          refId={refId}
          actionData={actionData}
        />
      ) : null}
      {selectedOption?.next?.options &&
      !isArray(selectedOption?.next?.options) &&
      selectedOption?.next?.options === "TAG_OPTIONS" ? (
        <div className="option-box">
          {selectedOption.next.name ? (
            <div className="ob-label">{selectedOption.next.name}?</div>
          ) : null}
          <Select
            placeholder="Select an option"
            value={refId}
            size="medium"
            onChange={(val) => updateSteps(steps, val)}
            popupClassName="pp-select-dropdown"
          >
            {dynamicOptions?.TAG_OPTIONS?.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                <CustomTag
                  backgroundColor={option.background_color}
                  fontColor={option.font_color}
                >
                  {option.description || "---"}
                </CustomTag>
              </Select.Option>
            ))}
          </Select>
        </div>
      ) : null}

      {selectedOption?.next?.options &&
      !isArray(selectedOption?.next?.options) &&
      selectedOption?.next?.options === "TASK_OPTIONS" ? (
        <div className="option-box">
          {selectedOption.next.name ? (
            <div className="ob-label">{selectedOption.next.name}?</div>
          ) : null}
          <Select
            placeholder="Select an option"
            value={refId}
            size="medium"
            onChange={(val) => updateSteps(steps, val)}
            popupClassName="pp-select-dropdown"
          >
            {dynamicOptions?.TASK_OPTIONS?.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      ) : null}

      {selectedOption?.next?.options &&
      !isArray(selectedOption?.next?.options) &&
      selectedOption?.next?.options === "WORKFLOW_OPTIONS" ? (
        <div className="option-box">
          {selectedOption.next.name ? (
            <div className="ob-label">{selectedOption.next.name}?</div>
          ) : null}
          <Select
            placeholder="Select an option"
            value={refId}
            size="medium"
            onChange={(val) => updateSteps(steps, val)}
            popupClassName="pp-select-dropdown"
          >
            {dynamicOptions?.WORKFLOW_OPTIONS?.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.name} - {option.total_tasks} task
                {option.total_tasks === 1 ? "" : "s"}
              </Select.Option>
            ))}
          </Select>
        </div>
      ) : null}

      {/* HANDLE CUSTOM OPTIONS */}
      {selectedOption?.next?.custom_options?.length > 0 ? (
        <>
          {selectedOption?.next?.custom_options.map((co, i) => (
            <div key={`co-${i}-${co.name}`} className="option-box">
              <div className="ob-label">{co.name}?</div>
              <>
                {typeof customOptions[co.type] === "function"
                  ? customOptions[co.type](
                      actionData,
                      updateOptions,
                      listOptions,
                    )
                  : null}
              </>
            </div>
          ))}
        </>
      ) : null}
    </>
  );
};

function SelectActions(props) {
  const [steps, setSteps] = useState([]);
  const [refId, setRefId] = useState();

  const checkUpdate = (newValue, refId) => {
    const joinedVal = newValue.join("_");
    const required = requiresRef?.includes(joinedVal);
    if (allActions.includes(joinedVal) && required && refId) {
      props.updateForm({
        action_key: joinedVal,
        action_ref_id: refId,
        action_options: [],
      });
    } else if (allActions.includes(joinedVal) && !required && !refId) {
      props.updateForm({
        action_key: joinedVal,
        action_ref_id: undefined,
        action_options: [],
      });
    } else {
      props.updateForm({
        action_key: undefined,
        action_ref_id: undefined,
        action_options: [],
      });
    }
    setSteps(newValue);
    setRefId(refId);
  };

  const { options } = props;
  const dynamicOptions = {
    TAG_OPTIONS: (options?.tags || [])?.map((t) => {
      return {
        ...t,
        name: t.key,
        value: t.id,
      };
    }),
    WORKFLOW_OPTIONS: (options?.workflows || [])?.map((w) => {
      return {
        ...w,
        name: w.name,
        value: w.id,
      };
    }),
    TASK_OPTIONS: (options?.templates || [])?.map((w) => {
      return {
        ...w,
        name: `${w.title} (${capitalize(w.type)})`,
        value: w.id,
      };
    }),
  };

  return (
    <div className="select-actions">
      <RecursiveSelect
        dynamicOptions={dynamicOptions}
        options={actionTypes}
        listOptions={options}
        depth={0}
        steps={steps}
        updateSteps={checkUpdate}
        updateOptions={props.updateOptions}
        refId={refId}
        actionData={props.actionData}
      />
    </div>
  );
}

export default SelectActions;
