import React from "react";
import moment from "moment";
import { formatMoney } from "_assets/js/helpers";

export const LongTerms = ({ form, plaidData, agreement }) => {
  const css = `
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #000;
      font-family:
      Georgia,
      Times,
      Times New Roman,
      serif !important;
      b {
        font-weight: 600;
      }
    }

    table,
    th,
    td {
      font-size: 16px !important;
      border: 1px solid black;
      border-collapse: collapse;
      padding: 4px !important;
      font-family:
      Georgia,
      Times,
      Times New Roman,
      serif !important;
    }

    table {
      width: 100%;

    }
    table tbody tr:nth-child(odd) {
      background-color: #ededed;
    }
    th,
      td {
        text-align: left;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        color: #000;
        padding: 0 4px;
        font-family:
      Georgia,
      Times,
      Times New Roman,
      serif !important;
      }
      th {
        font-weight: 600;
        background: #000000;
        color: #fff;
      }
      ol > li {
        margin-bottom: 24px;
        font-size: 16px !important;
      font-family:
      Georgia,
      Times,
      Times New Roman,
      serif !important;
      }
    a {
      font-weight: 600;
      color: #000;
      font-family:
      Georgia,
      Times,
      Times New Roman,
      serif !important;
    }
    h1 {
      margin-top: 0;
      font-family:
      Georgia,
      Times,
      Times New Roman,
      serif !important;
    }
    h2 {
      margin-top: 0;
      font-family:
      Georgia,
      Times,
      Times New Roman,
      serif !important;
    }
    h3 {
      margin-top: 0;
      font-family:
      Georgia,
      Times,
      Times New Roman,
      serif !important;
    }
    .page-break {
  page-break-before: always;
  border-top: 1px solid #DDDDDD;
  margin: 24px 0;
}
`;
  const clientName = `${form?.first_name} ${form?.last_name}`;
  const clientAddress = `${form?.address} ${form?.address_line_two}, ${form?.city}, ${form?.state}, ${form?.zip}`;
  const clientEmail = `${form?.email}`;
  const clientPhone = `${form?.phone}`;
  const firmLegalName = `Freedom Law Group`;
  const firmName = `Frontier Law Group`;
  const firmLegalState = `FL`;
  const firmEmail = `Support@FrontierLawGroup.org`;
  const firmPhone = `888-385-2495`;
  const firmAddress = `7901 4th Street N, Ste 10026, St. Petersburg, FL 33702`;

  const planMonthly = formatMoney(form?.plan?.amount);
  const planMonths = form?.plan?.months;
  const planTotal = formatMoney(planMonthly * planMonths);

  const today = `${moment().format("MM/DD/YY")}`;
  return (
    <div id="contract-terms-pdf">
      <style>{css}</style>
      <h1>{firmName}</h1>
      <h2>LEGAL SERVICES AGREEMENT </h2>
      <p>{clientName}</p>
      <p>{clientAddress}</p>
      <div className="page-break"></div>
      <h3>LEGAL SERVICES AGREEMENT</h3>
      <p>
        This Legal Services Agreement (the "Agreement") is made and entered into
        as of {today}, by and between {clientName}, an individual (hereinafter
        referred to as the "Client") and {firmLegalName}, a {firmLegalState}{" "}
        Professional Corporation. (hereinafter referred to as "{firmName}").
      </p>
      <h3>RECITALS</h3>
      <p>
        WHEREAS, {firmName} specializes in litigation and was approached by
        Client regarding representation of Client in connection with certain
        existing or anticipated disputes Client has with certain purported
        creditors;
      </p>
      <p>
        WHEREAS, {firmName} is in the business of advocating for consumer rights
        and assisting clients with the defense of collection actions and related
        legal issues;
      </p>
      <p>
        WHEREAS, the Client desires to retain {firmName} to represent them in
        accordance with the terms and conditions set forth in this Agreement;
      </p>
      <p>
        NOW, THEREFORE, in consideration of the mutual promises and covenants
        contained in this Agreement, and for other good and valuable
        consideration, the receipt and sufficiency of which is hereby
        acknowledged, the parties agree as follows:
      </p>
      <h4>1. Scope of Services</h4>
      <p>
        {firmName} agrees to provide the following services related to the
        Enrolled Accounts listed in Exhibit A, in compliance with applicable
        regulations:
      </p>
      <p>
        <b>Legal Representation:</b> {firmName} will begin immediate
        representation, assist in stopping collection calls, request
        authentication and verification of all enrolled accounts, argue and
        reconcile accounts with creditors and/or collectors, send letters to
        third party debt collectors on Client’s behalf requiring documentation
        that the debt collector is authorized to collect the debt, examine the
        Client's accounts and creditor actions in light of the Fair Debt
        Collection Practices Act (FDCPA) as governed by 15 U.S.C. § 1692 et seq.
        and other such available regulations, and advise Client on all available
        remedies. This may involve disputing the accuracy of debts under 15
        U.S.C. § 1692g(b), investigating potential FDCPA violations as per 15
        U.S.C. § 1692c, and negotiating the resolution of the dispute in
        litigation or the immediate threat of litigation.
      </p>
      <p>
        {firmName} will represent Client and administer a legal plan tailored to
        the Client's specific needs. The customized legal plan will address
        matters related to the Client's disputes and financial situation,
        considering the unique circumstances and requirements of the Client.
        Additionally, {firmName} will provide legal representation for the
        Client, safeguarding their rights and interests during the Term of this
        Agreement.
      </p>
      <p>
        If Client receives a court summons, or other court document, for any of
        the Enrolled Accounts after retaining {firmName}, Client agrees to
        submit a complete copy of such court document to {firmName} via email to
        ({firmEmail}) within two (2) days of receipt or as soon as possible.
        After {firmName} receives your legal document, {firmName} agrees to
        assign you an attorney licensed in the jurisdiction in which the legal
        dispute is pending to represent you. No additional fees will be assessed
        for such representation as the fees you are paying pursuant to this
        Agreement are in anticipation of such representation. If any fee paid as
        a flat fee to {firmName} is not earned at the time it is paid, and
        Client terminates their representation by {firmName} prior to {firmName}{" "}
        earning such fee, Client would be entitled to a refund of those unearned
        fees. Finally, Client may demand that {firmName} deposit all fees paid
        by Client into {firmName}’s trust account by sending such request in
        writing to {firmEmail}.{" "}
      </p>
      <p>
        Client understands and agrees that the legal services contemplated by
        this Agreement will be provided immediately upon execution of this
        Agreement and will continue until the dispute with regard to the
        Enrolled Accounts is complete, without limitation. The fees contemplated
        herein are earned when received and will not be held in trust. The fees
        will be deposited into the operating account of {firmName}, and by
        signing below Client formally consents to such handling of funds paid
        pursuant to this Agreement.{" "}
      </p>
      <p>
        Finally, Client understands and agrees that {firmName} may employ
        attorneys to assist with the representation of Client that are
        independent contractors or employees of another law firm or law
        practice. Client hereby consents to {firmName}’s use of any licensed
        attorney to represent or assist in the representation of Client provided
        such licensed attorney is in good standing and admitted to practice in
        the jurisdiction in which the dispute is pending, whether that dispute
        is in court or is pre-litigation but with litigation contemplated or
        imminent.{" "}
      </p>
      <p>
        <b>Administrative Services:</b> {firmName} will offer support by
        managing mail related to the Client's Enrolled Accounts, processing
        scheduled payments in accordance with this Agreement, and communicating
        with third parties including the Enrolled Accounts on behalf of the
        Client, in accordance with applicable laws.{" "}
      </p>
      <p>
        The Client acknowledges and agrees that {firmName} may engage third
        parties to complete certain tasks related to the Services in this
        section. {firmName} will ensure that such third parties are qualified to
        perform the services required, and that the confidentiality and privacy
        of the Client's information is maintained.{" "}
      </p>
      <p>
        <b>Understanding of Services:</b> The Client acknowledges and agrees
        that the Services provided under this Agreement are not a credit repair,
        debt settlement, debt relief or other debt management "program” and that
        all fees paid are for the work performed and not for any other purpose
        including the payment or settlement of any debt.
      </p>
      <p>
        <b>No Guarantees:</b> {firmName} makes no promises or representations
        regarding the payment or settlement of the Client's Enrolled Accounts or
        the improvement or repair of the Client's credit score. The outcome of
        the Services, including any negotiations or legal proceedings, cannot be
        guaranteed or predicted with certainty. The Client understands that the
        effectiveness of the Services may depend on various factors, including
        the Client's individual financial circumstances, the willingness of
        adverse parties to negotiate, and applicable laws and regulations.
        Client is not entitled to a refund of any fees if client should cancel
        or terminate the representation by {firmName} at any time, and the fees
        set forth in this agreement are due regardless of Client’s cancellation
        or termination.{" "}
      </p>
      <h4>2. Limited Power of Attorney </h4>
      <p>
        As part of the Services provided under this Agreement, {firmName} shall
        have a Limited Power of Attorney granted by the Client to act on the
        Client's behalf in connection with the Enrolled Accounts specified in
        Exhibit A. The Limited Power of Attorney shall authorize {firmName} and
        its affiliates and assigned attorney(s) to:
      </p>
      <ol type="a">
        <li>
          Communicate and negotiate with adverse parties on the Client's behalf
          concerning the Client's Enrolled Accounts;
        </li>
        <li>
          Request and obtain any necessary documents, records, or information
          relating to the Client's Enrolled Accounts;
        </li>
        <li>
          Prepare, review, and execute any documents, agreements, or other
          instruments necessary to carry out the Services and achieve the
          objectives set forth in the customized legal plan;
        </li>
        <li>
          Perform any other acts reasonably necessary to fulfill the obligations
          and exercise the rights under this Agreement, provided that such acts
          are within the scope of the Services and the applicable laws and
          regulations.
        </li>
      </ol>
      <p>
        The Limited Power of Attorney shall be effective upon the execution of
        this Agreement by the Client and shall remain in effect until the
        termination or expiration of this Agreement, unless otherwise revoked by
        the Client in writing. The Client acknowledges that the Limited Power of
        Attorney is necessary for {firmName} to provide the Services under this
        Agreement and that any revocation of the Limited Power of Attorney may
        adversely affect the Services provided by {firmName}. Client may revoke
        this limited Power of Attorney at any time by submitting such request in
        writing.{" "}
      </p>
      <h4>3. Clients Rights and Responsibilities</h4>
      <p>
        <b>Rights.</b> The Client has the right to:
      </p>
      <ol type="a">
        <li>
          Receive clear and transparent communication from {firmName} and its
          assigned attorney(s) regarding the Services provided under this
          Agreement, including updates on the progress and status of the
          Client's case(s);
        </li>
        <li>
          Review and approve any proposed agreements, settlements, or other
          legally binding documents related to the Client's debts before they
          are executed or finalized;
        </li>
        <li>
          Revoke the Limited Power of Attorney granted to {firmName} under this
          Agreement, subject to the understanding that such revocation may
          adversely affect the Services provided by {firmName};
        </li>
        <li>
          Terminate this Agreement in accordance with the termination provisions
          set forth herein;
        </li>
        <li>
          Seek independent legal advice at any time during the term of this
          Agreement.
        </li>
      </ol>
      <p>
        <b>Responsibilities.</b> The Client is responsible for:
      </p>
      <ol type="a">
        <li>
          Providing accurate, complete, and up-to-date information to {firmName}{" "}
          and its assigned attorney(s) regarding the Client's Enrolled Accounts,
          financial circumstances, and any other relevant matters;
        </li>
        <li>
          Timely responding to requests for information, documents, or other
          assistance from {firmName} and its assigned attorney(s);
        </li>
        <li>
          Timely making payments to {firmName} in accordance with the fees and
          payment terms specified in Exhibit B;
        </li>
        <li>
          Cooperating with {firmName} and its assigned attorney(s) in good faith
          and providing any necessary support to facilitate the provision of the
          Services under this Agreement;
        </li>
        <li>
          Notifying {firmName} and its assigned attorney(s) promptly of any
          changes in the Client's contact information, financial circumstances,
          or other relevant matters that may affect the Services provided under
          this Agreement.
        </li>
      </ol>
      <p>
        By entering into this Agreement, the Client acknowledges and agrees to
        comply with their rights and responsibilities as set forth in this
        Section.
      </p>
      <h4>4. Fees and Expenses</h4>
      <p>
        In consideration for the Services outlined above in part 1 (Scope of
        Services), the Client shall pay a Service fee of {planMonthly} for a
        period of {planMonths} month(s) for a total fee of {planTotal}, as set
        forth in Exhibit B. This fee has been broken down into installments
        solely to ease the financial burden of such fees on the Client. The fee
        is for legal services provided to Client by {firmName}, as outlined
        above, and includes all fees and costs associated with the
        representation of Client by {firmName} under this Agreement. Client
        acknowledges that the fee is earned by {firmName} upon receipt due to
        services performed prior to the collection of such fee, and fees are not
        held in trust or escrow or used to pay creditors. You will not receive
        invoices or billing statements from {firmName} for services performed as
        the fee you agree to pay for such services is the flat fee contained in
        this agreement.
      </p>
      <p>
        Filing fees, Court cost and other expenses incurred by {firmName} its
        affiliates or assigned attorney(s) related to this Agreement are already
        included in the flat fee set forth in this Agreement, and will not
        require any additional payment by Client.{" "}
      </p>
      <p>
        Returned Payment Fee. If the Client's payment is returned due to
        Non-Sufficient Funds (NSF) or any other reason, the Client will be
        charged a fee of up to $25 for each occurrence. The Client authorizes{" "}
        {firmName} to scan and monitor their account to ensure the availability
        of sufficient funds and to draft the outstanding amount, including the
        Returned Payment Fee, once sufficient funds are detected.
      </p>
      <p>
        The Client acknowledges and agrees that all fees, charges, and payments
        made to {firmName} under this Agreement have been earned prior to being
        collected, and as such are non-refundable. The Client shall not be
        entitled to any refund, reimbursement, or credit for any services
        rendered, or work performed, even if the Client is dissatisfied with the
        results or terminates the Agreement before the completion of the
        services. The fees set forth below are due regardless of the
        cancellation or termination of services {firmName} has provided to
        client prior to such cancellation or termination.{" "}
      </p>
      <h4>5. Payment</h4>
      <p>
        All payments related to this Agreement shall be automatically deducted
        from the Client’s account as authorized in the Payment Authorization
        attached hereto as Exhibit C. Client shall have the ability to update
        the Payment Authorization by submitting such request in writing to{" "}
        {firmName}. Any changes made to the Payment Authorization shall be
        applied to the next scheduled payment so long as that payment is at
        least three (3) business days prior to the date the change is made.{" "}
      </p>
      <h4>6. Adjustment of Payment</h4>
      <p>
        If {firmName} adjusts any payment by Client pursuant to this Agreement
        such adjustment is not a waiver of any right held by {firmName} nor is
        it a modification of this Agreement in any way. Rather, such adjustment
        is an accommodation made to client for client’s benefit and is based
        upon Client’s financial need.{" "}
      </p>
      <h4>7. Term and Termination</h4>
      <p>
        This Agreement shall commence on the Signature Date Hereto and continue
        until the Client’s dispute with regard to the Enrolled Accounts has
        concluded (the “Term”), unless earlier terminated as provided herein.{" "}
      </p>
      <p>
        Either Party may terminate this Agreement by providing the other Party
        with 30 days' written notice. However, if the Client is currently
        represented in an ongoing legal proceeding by an attorney assigned by{" "}
        {firmName} or as a result of this Agreement, the termination will only
        become effective once a substitute attorney is appointed by the court or
        a substitution of counsel is agreed to by Client and submitted in
        writing to the court in such manner as the court requires.
      </p>
      <p>
        In the event that this Agreement is terminated by the Client prior to
        the end of the Term, {firmName} reserves the right to charge the Client
        the balance of the Service Fee due under this Agreement. The amount
        shall be accelerated and due in full on the next scheduled payment date
        and shall be paid in accordance with the Payment Authorization attached
        hereto as Exhibit C.
      </p>
      <p>
        Client acknowledges and agrees that by beginning immediate
        representation {firmName} has earned any and every payment made by
        Client, and Client is not entitled to any refund, reimbursement, or
        credit for any services rendered, work performed, or holding the firm
        and its attorneys and staff available to Client and Client’s creditors
        whether accessed or not, even if the Client is dissatisfied with the
        results or terminates the Agreement before the completion of the
        services.{" "}
      </p>
      <p>
        The termination of this Agreement shall not affect any accrued rights or
        liabilities of either Party, including without limitation, any right to
        payment or any indemnification obligations.
      </p>
      <h4>8. Assignment</h4>
      <p>
        {firmName} may assign this Agreement, in whole or in part, to any other
        entity without the prior written consent of the Client provided such
        entity, through its employees or agents, has the license or licenses
        necessary to perform the services required under this Agreement.{" "}
        {firmName} may also assign its rights, duties, and obligations under
        this Agreement related to the Scope of Services to any such assignee.
        The Client agrees that {firmName} may share any and all information
        regarding the Scope of Services with any such assignee, and that any
        such assignee shall have the right to enforce this Agreement against the
        Client to the same extent as if it were an original party to this
        Agreement.
      </p>
      <h4>9. Assignment of Accounts Receivable</h4>
      <p>
        The Client acknowledges and agrees that {firmName} may, without further
        notice or consent, sell, transfer, or assign all or any portion of its
        accounts receivable and/or contracted payments related to this Agreement
        to any third party. The Client understands that the buyer, receiver or
        assignee may incur damages if the Client fails to fulfill their
        financial obligations under this Agreement, and the Client shall be
        responsible for compensating such damages. The Client agrees that any
        assignment or transfer shall not relieve them of their obligation to pay
        fees or amounts due to {firmName} under this Agreement, and they commit
        to making all payments as outlined in this Agreement, regardless of any
        assignment or transfer. The buyer, receiver or assignee's interests
        shall be protected from the Client's nonpayment, and the assignee may
        pursue remedies in accordance with the terms of this Agreement and
        applicable law.
      </p>
      <h4>10. Confidentiality & Attorney-Client Privilege</h4>
      <p>
        <b>Confidentiality Obligations:</b> Each party agrees to hold in strict
        confidence and not to disclose any Confidential Information (as defined
        below) of the other party. Each party shall take all reasonable
        precautions to prevent any unauthorized use, disclosure, publication, or
        dissemination of the other party's Confidential Information. {firmName}{" "}
        and its assigned attorney(s) shall maintain the confidentiality of all
        information received from the Client in accordance with the
        attorney-client privilege.
      </p>
      <p>
        <b>Attorney-Client Privilege:</b> All information provided by the Client
        to {firmName} and its assigned attorney(s) in connection with the
        Services shall be treated as confidential and subject to the
        attorney-client privilege, to the extent applicable under the law.{" "}
        {firmName} and its assigned attorney(s) shall not disclose any such
        information to any third party, except as required to complete the Scope
        of Services, by law, court order, or with the express written consent of
        the Client.
      </p>
      <p>
        <b>Definition of Confidential Information:</b> "Confidential
        Information" means any non-public information, whether in oral, written,
        electronic, or other form, which is designated as confidential or
        proprietary or which, by its nature, should reasonably be considered to
        be confidential or proprietary. Confidential Information includes, but
        is not limited to, the Client's personal and financial information, the
        contents of the customized legal plan, and any communications between
        the Client and {firmName}, its Affiliates or its assigned attorney(s).
      </p>
      <p>
        <b>Exceptions to Confidentiality:</b> The obligations set forth in this
        shall not apply to any information that: (a) is or becomes publicly
        available other than as a result of a breach of this Agreement; (b) is
        lawfully obtained by the receiving party from a third party not under an
        obligation of confidentiality to the disclosing party; (c) is
        independently developed by the receiving party without reference to the
        disclosing party's Confidential Information; or (d) is required to be
        disclosed by law or by order of a court or governmental authority,
        provided that the receiving party promptly notifies the disclosing party
        of such requirement and reasonably cooperates with the disclosing party,
        at the disclosing party's expense, in seeking a protective order or
        other appropriate protection of the Confidential Information.
      </p>
      <p>
        Notwithstanding the provisions set forth herein, {firmName} may disclose
        Confidential Information to third parties as necessary to perform the
        Services under this Agreement, provided that: (a) {firmName} informs the
        third party of the confidential nature of the information; and (b) the
        third party agrees in writing to be bound by confidentiality obligations
        substantially similar to those contained in this Agreement. The Client
        acknowledges and agrees that {firmName} may share the Client's
        Confidential Information with third parties engaged by {firmName} to
        provide services in connection with this Agreement. In the event that a
        third party engaged by {firmName} in connection with this Agreement
        misuses the Client's Confidential Information in violation of the
        confidentiality obligations set forth herein, the Client agrees to
        indemnify, defend, and hold harmless {firmName}, its officers,
        directors, employees, and agents from and against any and all claims,
        damages, liabilities, costs, and expenses (including reasonable
        attorneys' fees) arising out of or related to any third-party claim
        resulting from such misuse.
      </p>
      <h4>11. Indemnification </h4>
      <p>
        The Client agrees to indemnify, defend, and hold harmless {firmName} and
        its affiliates, assignees, partners, directors, officers, employees, and
        agents, from and against any and all claims, damages, liabilities,
        costs, and expenses (including reasonable attorneys' fees) arising out
        of or related to the Client's breach of this Agreement, or the Client's
        use of the services provided by {firmName}.
      </p>
      <p>
        The Client further agrees to indemnify and hold harmless {firmName} and
        its affiliates, assignees, partners, directors, officers, employees, and
        agents from and against any and all claims, damages, liabilities, costs,
        and expenses (including reasonable attorneys' fees) arising out of or
        related to any third-party claim or action, including but not limited to
        claims or actions by debt collectors, credit bureaus, or other
        creditors, related to the services provided by {firmName}.
      </p>
      <p>
        This indemnification obligation will survive the termination or
        expiration of this Agreement. The Client acknowledges that this
        indemnification clause is a material inducement for {firmName} to enter
        into this Agreement and that the indemnification obligation contained in
        this clause is separate and distinct from the obligations of {firmName}{" "}
        under this Agreement.
      </p>
      <h4>12. Limitation of Liability</h4>
      <p>
        Unless otherwise specified in this Agreement, In no event shall either
        party be liable to the other party for any indirect, incidental,
        consequential, special, or exemplary damages, including, without
        limitation, damages for loss of profits, goodwill, use, data, or other
        intangible losses, even if such party has been advised of the
        possibility of such damages.
      </p>
      <p>
        <b>Maximum Liability:</b> The total cumulative liability of {firmName}{" "}
        arising out of or related to this Agreement, whether based on contract,
        tort, or any other legal theory, shall not exceed the total fees paid by
        the Client to {firmName} under this Agreement.
      </p>
      <h4>13. Governing Law</h4>
      <p>
        This Agreement shall be governed by and construed in accordance with the
        laws of the State of Pennsylvania.{" "}
      </p>
      <h4>14. Entire Agreement</h4>
      <p>
        This Agreement, together with any exhibits and attachments hereto,
        constitutes the entire agreement between the parties with respect to the
        subject matter hereof and supersedes all prior and contemporaneous
        agreements, understandings, negotiations, and discussions, whether oral
        or written, of the parties.
      </p>
      <h4>15. Amendments </h4>
      <p>
        This Agreement may only be amended or modified by written agreement
        signed by both parties.
      </p>
      <h4>16. Notices</h4>
      <p>
        All notices, requests, demands, and other communications under this
        Agreement must be in writing and will be deemed to have been duly given
        on the date of receipt if delivered personally or sent by email, or on
        the date of actual delivery if sent by certified mail, return receipt
        requested, postage prepaid, to the Parties at the addresses specified
        below or to such other address as a Party may designate by notice to the
        other Party:
      </p>
      <p>
        Notice to <b>{clientName}:</b> {clientEmail}
      </p>
      <p>
        Notice to <b>{firmName}:</b> {firmEmail}
      </p>
      <p>
        Either Party may change its address for the purpose of receiving notices
        by providing written notice to the other Party in accordance with the
        terms of this clause.
      </p>
      <h4>17. Severability</h4>
      <p>
        If any provision of this Agreement is held by a court of competent
        jurisdiction to be invalid, illegal, or unenforceable for any reason,
        such provision shall be modified to the extent necessary to render it
        enforceable without losing its intent or severed from this Agreement if
        no such modification is possible, and the other provisions of this
        Agreement shall remain in full force and effect.
      </p>
      <h4>18. Waiver</h4>
      <p>
        Any waiver of a default under this Agreement shall not be a waiver of
        any other or subsequent default.
      </p>
      <h4>19. Counterparts</h4>
      <p>
        This Agreement may be executed in counterparts, each of which shall be
        deemed an original, but all of which together shall constitute one and
        the same instrument.
      </p>
      <h4>20. Electronic Authorization</h4>
      <p>
        You acknowledge that {firmName} reserves the right to contact you
        electronically, which may include but is not limited to communication by
        email, text message, push notification, telephone, voicemail (including
        ringless voicemail or voicemail “drop”) or e-signature platform. You
        understand and agree that such communication involves the transmission
        of data electronically, which carries the risk of disclosure to a third
        party regarding financial data and that {firmName} will not be liable
        for any inadvertent disclosure of information. You further acknowledge
        that an electronic version of this signed agreement will be as valid as
        the original signed agreement.
      </p>
      <h4>21. Professional Liability Insurance</h4>
      <p>
        {firmName} maintains professional liability insurance applicable to its
        representation of you which is limited to $1,000,000.00 per claim filed
        by you pursuant to the terms of such policy. Any claim under this policy
        shall require you to contact {firmName} and disclose such claim,
        including all details regarding the same.{" "}
      </p>
      <h4>22. Class Action Waiver </h4>
      <p>
        Any Claim must be brought in the parties’ individual capacity, and not
        as a plaintiff or class member in any purported class, collective,
        representative, multiple plaintiff, or similar proceeding (“Class
        Action”). The parties expressly waive any ability to maintain any Class
        Action in any forum. The arbitrator shall not have authority to combine
        or aggregate similar claims or conduct any Class Action nor make an
        award to any entity or person not a party to the arbitration. Any claim
        that all or part of this Class Action Waiver is unenforceable,
        unconscionable, void, or voidable may be determined only by a court of
        competent jurisdiction and not by an arbitrator. THE PARTIES UNDERSTAND
        THAT THEY WOULD HAVE HAD A RIGHT TO LITIGATE THROUGH A COURT, TO HAVE A
        JUDGE OR JURY DECIDE THEIR CASE AND TO BE PARTY TO A CLASS OR
        REPRESENTATIVE ACTION, HOWEVER, THEY UNDERSTAND AND CHOOSE TO HAVE ANY
        CLAIMS DECIDED INDIVIDUALLY, THROUGH ARBITRATION.
      </p>
      <h4>23. Arbitration </h4>
      <p>
        If a dispute arises from or relates to this contract or the breach
        thereof, and if the dispute cannot be settled through direct
        discussions, the parties agree to endeavor first to settle the dispute
        by mediation administered by the American Arbitration Association under
        its Commercial Mediation Procedures before resorting to arbitration. The
        parties further agree that any unresolved controversy or claim arising
        out of or relating to this contract, or breach thereof, shall be settled
        by arbitration administered by the American Arbitration Association in
        accordance with its Commercial Arbitration Rules and judgment on the
        award rendered by the arbitrator(s) may be entered in any court having
        jurisdiction thereof.
      </p>
      <p>
        Claims shall be heard by a single arbitrator. List Process - Note that
        since this is the default process contained in the AAA's Commercial
        Rules, no specific language will be added to your arbitration agreement
        regarding this selection. The place of arbitration shall be San Diego
        County, California The arbitration shall be governed by the laws of the
        State of California The arbitration will be based on the submission of
        documents and there shall be no in-person or oral hearing. Time is of
        the essence for any arbitration under this agreement and arbitration
        hearings shall take place within 90 days of filing and awards rendered
        within 120 days. Arbitrator(s) shall agree to these limits prior to
        accepting appointment. The arbitrators will have no authority to award
        punitive or other damages not measured by the prevailing party's actual
        damages, except as may be required by statute. The arbitrator(s) shall
        not award consequential damages in any arbitration initiated under this
        section. Any award in an arbitration initiated under this clause shall
        be limited to monetary damages and shall include no injunction or
        direction to any party other than the direction to pay a monetary
        amount. Except as may be required by law, neither a party nor an
        arbitrator may disclose the existence, content, or results of any
        arbitration hereunder without the prior written consent of both parties.
      </p>
      <h4>24. Licensing</h4>
      <p>
        You understand and acknowledge that {firmName} is a Pennsylvania
        professional law corporation and employs or associates with attorneys
        licensed to practice law in the jurisdiction in which you reside.{" "}
        {firmName} shall maintain an active, licensed attorney in all
        jurisdictions in which it operates, and you have a right to speak with
        such licensed counsel. Although {firmName} is based in Pennsylvania, it
        employs or associates for all purposes with attorneys in all
        jurisdictions in which its clients reside.
      </p>
      <div className="page-break"></div>
      <p>
        IN WITNESS WHEREOF, the parties have executed this Agreement as of the
        signature date hereto.
      </p>
      <p>
        <b>CLIENT:</b>
      </p>
      <p>
        <b>Printed Name:</b> {clientName}
      </p>
      <p>
        <b>Address:</b> {clientAddress}
      </p>
      <p>
        <b>Email:</b> {clientEmail}
      </p>
      <p>
        <b>Phone:</b> {clientPhone}
      </p>
      <p>
        <b>Credit Report Acceptance Date:&nbsp;&nbsp;</b>
        {moment(
          parseInt(agreement?.application?.audit?.credit_report_date),
        ).format("M/D/YYYY")}
      </p>
      <p>
        <b>Plaid Acceptance Date:&nbsp;&nbsp;</b>
        {agreement?.application?.audit?.plaid_date
          ? moment(parseInt(agreement?.application?.audit?.plaid_date)).format(
              "M/D/YYYY",
            )
          : "N/A"}
      </p>
      <p>
        <b>Power of Attorney Acceptance Date:&nbsp;&nbsp;</b>
        {moment().format("M/D/YYYY")}
      </p>{" "}
      <p>
        <b>Agreement Acceptance Date:&nbsp;&nbsp;</b>
        {moment().format("M/D/YYYY")}
      </p>
      <p>
        <b>IP Address:&nbsp;&nbsp;</b>
        {agreement?.ip_address}
      </p>
      <p>
        <b>FIRM:</b>
      </p>
      <p>
        <b>Name:</b> {firmName}
      </p>
      <p>
        <b>Address:</b> {firmAddress}
      </p>
      <p>
        <b>Email:</b> {firmEmail}
      </p>
      <p>
        <b>Phone:</b> {firmPhone}
      </p>
      <div className="page-break"></div>
      <h3>Exhibit A - Enrolled Accounts</h3>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Creditor</th>
            <th>Account #</th>
          </tr>
        </thead>
        <tbody>
          {agreement?.tradelines?.map((t, i) => (
            <tr key={t.id}>
              <td>{i + 1}</td>
              <td>{t.tradeline?.customerName}</td>
              <td>########{t.tradeline?.accountNumber?.slice(-4)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p>
        <b>Total Enrolled Debt:&nbsp;&nbsp;</b>
        {formatMoney(form?.plan?.debt_amount || 0)}
      </p>
      <h4>CLIENT ACKNOWLEDGEMENT:</h4>
      <p>
        <b>Printed Name:&nbsp;&nbsp;</b>
        {clientName}
      </p>
      <p>
        <b>Date:&nbsp;&nbsp;</b>
        {today}
      </p>
      <div className="page-break"></div>
      <h3>Exhibit B - Schedule of Payments</h3>
      <p>
        Payments will be due and drafted according to the following schedule:
      </p>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Payment Date</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {agreement?.payments?.map((p, i) => (
            <tr key={`pay-agree-${i}`}>
              <td>{i + 1}</td>
              <td>
                {moment(parseInt(p?.draft_date)).utc().format("M/D/YYYY")}
              </td>
              <td>{formatMoney(p?.amount || 0)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p>
        This schedule of payments is subject to change due to holidays, other
        applicable circumstances, or as determined by the terms of the
        Agreement. If a scheduled payment date falls on a holiday or a
        non-business day, the payment will be automatically drafted on the
        preceding business day, ensuring the flexibility and accuracy of the
        payment process. {firmName} may commence the drafting of a payment up to
        three (3) business days prior to the listed payment date.{" "}
      </p>
      <p>CLIENT ACKNOWLEDGEMENT:</p>
      <p>
        <b>Printed Name:&nbsp;&nbsp;</b>
        {clientName}
      </p>
      <p>
        <b>Date:&nbsp;&nbsp;</b>
        {today}
      </p>
      <div className="page-break"></div>
      <h3>Exhibit C - Payment Authorization</h3>
      <p>
        I {clientName} authorize {firmName} or its authorized agent to initiate
        debit entries to my account as indicated below and, if necessary,
        initiate adjustments for any transactions credited or debited in error.
        This authorization is to remain in full force and effect until I have
        canceled it in writing. I agree to pay all fees associated with this
        transaction.
      </p>
      <p>
        <b>Bank Name:&nbsp;&nbsp;</b>
        {agreement?.application?.bank?.name ||
          plaidData?.institution?.name}{" "}
      </p>
      <p>
        <b>Checking or Saving:&nbsp;&nbsp;</b>
        {agreement?.application?.bank?.account_type?.toUpperCase() ||
          plaidData?.account?.subtype?.toUpperCase()}
      </p>
      <p>
        <b>Account Number:&nbsp;&nbsp;</b>
        ########
        {agreement?.application?.bank?.last_four || plaidData?.account?.mask}
      </p>
      <p>
        <b>Address:&nbsp;&nbsp;</b>
        {form?.address} {form?.address_line_two}
      </p>
      <p>
        <b>City:&nbsp;&nbsp;</b>
        {form?.city}
      </p>
      <p>
        <b>State:&nbsp;&nbsp;</b>
        {form?.state}
      </p>
      <p>
        <b>Zip:&nbsp;&nbsp;</b>
        {form?.zip}
      </p>
      <p>
        <b>Email Address:&nbsp;&nbsp;</b>
        {form?.email}
      </p>
      <p>
        <b>Primary Phone #:&nbsp;&nbsp;</b>
        {form?.phone}
      </p>
      <p>
        <b>Last 4 digits of social security #:&nbsp;&nbsp;</b>
        {form?.ssn_last_4}
      </p>
      <p>
        I give permission to {firmName} or its authorized agent to scan my
        account using electronic or other means prior to deducting each payment
        to ensure that the account balance is sufficient to cover the payment.
      </p>
      <h4>Signature</h4>
      <p>
        <b>Printed Name:&nbsp;&nbsp;</b>
        {form?.first_name} {form?.last_name}
      </p>
      <p>
        <b>Date:&nbsp;&nbsp;</b>
        {today}
      </p>
    </div>
  );
};
