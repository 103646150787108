import React, { useState, useEffect } from "react";
import { Table, Empty } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { getColumns } from "./_data/Columns";
import { Button, message, Modal } from "@centrate-io/barn";
import ManageCategory from "./_modals/ManageCategory/ManageCategory";
import { IconPlus } from "@tabler/icons-react";
import { useTags } from "_contexts/useTags";
import { getErrors } from "_helpers/api";
import { removeTagCategory } from "App/Admin/_graphql/mutations/tag";
import "./CategoryTable.scss";

function CategoryTable(props) {
  const client = useApolloClient();
  const { tags, categories, reloadTags, reloadCategories } = useTags();
  const [manageModal, setManageModal] = useState(false);
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    pagination: { pageSize: 10, showSizeChanger: false },
  });

  // Load Payments
  const fetchCategories = async (tagsToo) => {
    if (tagsToo) await reloadTags();
    await reloadCategories();
    setState({
      loading: false,
    });
  };

  // Remove Bank Account
  const deleteCategory = async (tagCategoryId) => {
    Modal.confirm({
      title: "Are you sure you want remove this Category?",
      okText: "Remove Category",
      content: (
        <p>
          <b style={{ fontWeight: 600, color: "#000" }}>Warning:</b>
          <br />
          This CANNOT be done if tags are currently using this category.
          <br />
          <br />
          <b style={{ fontWeight: 600, color: "#000" }}>
            This cannot be undone once removed!
          </b>
        </p>
      ),
      onOk: async () => {
        try {
          await client.mutate({
            variables: {
              tag_category_id: tagCategoryId,
            },
            mutation: removeTagCategory,
          });
          message.success(`Category successfully removed`);
          fetchCategories();
        } catch (err) {
          console.log(err);
          message.error(getErrors(err) || "Error removing category, try again");
        }
      },
    });
  };

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let tableData = categories || [];
  const columns = getColumns(
    setManageModal,
    deleteCategory,
    tags,
    props.readOnly,
  );
  const isEmpty = (categories || [])?.length <= 0;

  return (
    <>
      {manageModal ? (
        <ManageCategory
          tagCategoryId={manageModal}
          reload={() => fetchCategories(true)}
          removeModal={() => setManageModal(false)}
        />
      ) : null}
      {isEmpty ? (
        <Empty
          title="Create Your First Category"
          description="Easily organize & manage tags by grouping into categories. Perfect for sorting by teams, locations, or any structure that works for you."
          action="Create Category"
          actionIcon={<IconPlus />}
          onClick={() => setManageModal("new")}
        />
      ) : (
        <div className="settings-categories">
          <div className="settings-search">
            <div className="ms-actions">
              {!props.readOnly ? (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => setManageModal("new")}
                >
                  <IconPlus />
                  Create Category
                </Button>
              ) : null}
            </div>
          </div>
          <Table
            columns={columns}
            loading={state.loading}
            pagination={state.pagination}
            rowKey={(record) => record.id}
            dataSource={tableData}
            showSorterTooltip={false}
            scroll={{
              y: "calc(80vh - 231px)",
              x: "max-content",
            }}
            locale={{
              emptyText: state.loading
                ? "Loading categories..."
                : "No categories found",
            }}
          />
        </div>
      )}
    </>
  );
}

export default CategoryTable;
