import React, { useState, useEffect } from "react";
import { Search, Table, Toggle, FilterTags } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { getColumns } from "./_data/Columns";
import { getModifyingColumns } from "./_data/ModifyingColumns";
import { adminSubscriptions } from "App/Admin/_graphql/queries/application";
import { getCrumbs } from "App/Admin/_helpers/crumbs";
import { Tooltip } from "@centrate-io/barn";
import "./ActiveCustomers.scss";

const defaultParams = {
  results: 10,
  page: 1,
  sortField: "total_unread",
  sortOrder: "descend",
};

function ActiveCustomers(props) {
  const client = useApolloClient();
  const [state, setState] = useState({
    loading: true,
    data: [],
    pagination: { pageSize: 10, showSizeChanger: false },
  });
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [unread, setUnread] = useState(false);
  const [filterTag, setFilterTag] = useState([]);

  const columns =
    props.category === "modifying"
      ? getModifyingColumns(props.category, props.setIsModifying)
      : getColumns(props.category, props.setIsModifying);

  // Load Applications
  const fetchApplications = async (params = defaultParams) => {
    const applicationData = await client.query({
      variables: {
        ...params,
        search: search,
        tag: filterTag,
        unread: unread,
        category: props.category || "active",
      },
      query: adminSubscriptions,
    });
    const pagination = { ...state.pagination };
    pagination.total = applicationData?.data?.adminSubscriptions?.pageTotal;
    pagination.totalAll = applicationData?.data?.adminSubscriptions?.total;
    setPage(params?.page || 1);
    props?.setCurrentCount(pagination.totalAll);
    setState({
      loading: false,
      data: applicationData?.data?.adminSubscriptions?.data || [],
      pagination: pagination,
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...state.pagination };
    pager.current = pagination.current;
    setState({ pagination: pager });
    fetchApplications({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      search: search,
      tag: filterTag,
      unread: unread,
    });
  };

  const toggleUnread = () => {
    const newValue = !unread;
    setUnread(newValue);
  };

  useEffect(() => {
    props?.setCurrentCount(0);
    props.setCrumbs(getCrumbs("subscriptions"));
    fetchApplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTag, unread]);

  const startResults = (page - 1) * defaultParams?.results + 1;
  const endResults = startResults + state?.data?.length - 1;

  return (
    <div>
      <div className="admin-search">
        <div className="admin-toggle">
          <b>Unread only?</b>
          <Tooltip title="Toggle show partners">
            <Toggle onClick={toggleUnread} checked={unread} />
          </Tooltip>
        </div>
        <FilterTags value={filterTag} onChange={(val) => setFilterTag(val)} />
        <Search
          placeholder="Search active customers..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onSearch={() => {
            fetchApplications({ ...defaultParams, search });
          }}
          style={{ width: 304 }}
        />
      </div>
      <div className="admin-table application-table">
        <Table
          columns={columns}
          loading={state.loading}
          pagination={state.pagination}
          onChange={handleTableChange}
          rowKey={(record) => record.id}
          dataSource={state?.data || []}
          showSorterTooltip={false}
          scroll={{
            y: "calc(100vh - 217px)",
            x: "max-content",
          }}
          locale={{
            emptyText: state.loading
              ? "Loading active customers..."
              : "No active customers found",
          }}
        />
      </div>
      {state?.data?.length > 0 && (
        <div className="admin-pagination">
          Showing {startResults}-{endResults} of {state?.pagination?.total || 0}{" "}
        </div>
      )}
    </div>
  );
}

export default ActiveCustomers;
