import { Select, TeamAvatar, UserAvatar } from "_styleguide";

export const customOptions = {
  ASSIGN_OPTIONS: (actionData, updateOptions, options) => {
    const { teams, users } = options;

    const updateAssign = (value) => {
      const userData = users?.find((t) => t.id === value);
      const teamData = teams?.find((t) => t.id === value);
      if (userData) {
        updateOptions("assign_id", value);
        updateOptions("assign_type", "user");
      } else if (teamData) {
        updateOptions("assign_id", value);
        updateOptions("assign_type", "team");
      }
    };

    const selectValue = actionData?.action_options?.find(
      (a) => a.name === "assign_id",
    )?.value;

    return (
      <Select
        value={selectValue}
        onChange={updateAssign}
        size="medium"
        popupClassName="pp-select-dropdown ppsd-task"
        placeholder="---"
        className="assign-select"
      >
        {teams?.map((t) => (
          <Select.Option key={t.id} value={t.id}>
            <TeamAvatar name={t.name} />
            {t.name}&nbsp;{`(${t.user_count})`}
          </Select.Option>
        ))}
        {users?.map((u) => (
          <Select.Option key={u.id} value={u.id}>
            <UserAvatar name={u.name} size="small" />
            {u.name}
          </Select.Option>
        ))}
      </Select>
    );
  },
};
