import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Header, Text, message } from "@centrate-io/barn";
import { Actions, Checkbox, Title } from "_styleguide";
import { accountTypeCredit, accountTypeLoan } from "_helpers/experian";
import { flatMoney } from "_assets/js/helpers";
import {
  IconChevronDown,
  IconChevronUp,
  IconLockOpen,
  IconCheck,
  IconX,
  IconAlertTriangle,
} from "@tabler/icons-react";
import { useApolloClient } from "@apollo/client";
import { saveTradelines } from "_styleguide/Actions/saveForm";
import { getErrors } from "_helpers/api";
import "./CreditSelect.scss";

const AccountBox = (props) => {
  const { data } = props;
  return (
    <div className="tradeline">
      {props?.type === "approved" && (
        <Checkbox
          checked={props?.selected}
          onChange={props.toggleTradeline}
          size="large"
        />
      )}
      <div className="account-box">
        <div className="top-line">
          <div>{data?.subscriberName || data?.customerName}</div>
          <div>{flatMoney(parseInt(data?.balanceAmount || 0))}</div>
        </div>
        <div className="bottom-line">
          <div>****{data?.accountNumber?.substr(-4)}</div>
        </div>
      </div>
    </div>
  );
};

function CreditSelect(props) {
  const client = useApolloClient();
  const navigate = useNavigate();
  const [tradelines, setTradelines] = useState([]);
  const [showDeclined, setShowDeclined] = useState(false);
  const [saving, setSaving] = useState(false);
  let totalApproved = 0;
  const approved = [],
    declined = [];
  (props?.tradeline || []).forEach((t) => {
    const isApproved =
      accountTypeLoan.includes(t.tradeline.accountType) ||
      accountTypeCredit.includes(t.tradeline.accountType);
    const balanceAmount = parseInt(t.tradeline?.balanceAmount || 0);
    const isOpen = t.tradeline.openOrClosed === "C" ? false : true;
    if (isApproved && balanceAmount > 0 && isOpen) {
      totalApproved += balanceAmount;
      approved.push(t);
    } else if (isOpen) {
      declined.push(t);
    }
  });
  const [approvedBalance, setApprovedBalance] = useState(totalApproved || 0);

  useEffect(() => {
    const initial = [];
    approved.forEach((a) => {
      if (!props.fromLoad) {
        initial.push(a.id);
      } else if (a.selected && props.fromLoad) {
        initial.push(a.id);
      }
    });
    setTradelines(initial);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function listSort(a, b) {
    return (
      parseInt(b?.tradeline?.balanceAmount || 0) -
      parseInt(a?.tradeline?.balanceAmount || 0)
    );
  }

  const toggleTradeline = (tradeline_id) => {
    let newTradelines = [];
    let newBalance = 0;
    if (tradelines.includes(tradeline_id)) {
      newTradelines = tradelines.filter((t) => t !== tradeline_id);
    } else {
      newTradelines = [...tradelines, tradeline_id];
    }
    setTradelines(newTradelines);
    approved
      .filter((a) => newTradelines.includes(a.id))
      .forEach((a) => {
        newBalance += parseInt(a.tradeline?.balanceAmount || 0);
      });
    setApprovedBalance(newBalance);
  };

  const handleClick = async (lines) => {
    try {
      setSaving(true);
      await saveTradelines(client, lines, props?.form?.id);
      setSaving(false);
      navigate(props?.next);
    } catch (err) {
      message.error(getErrors(err) || "Error selecting offer, try again");
      setSaving(false);
    }
  };

  const nonQualified = totalApproved < 5000;
  const notEnough = !nonQualified && approvedBalance < 5000;

  return (
    <div className="step-start credit-select-page">
      {!nonQualified ? (
        <React.Fragment>
          <Title>
            We found {flatMoney(totalApproved)} of eligible credit lines to
            include in your relief plan!
          </Title>
          <p className="credit-select-info">
            All approved lines have been selected. Please deselect any credit
            lines you would like to exclude from you relief plan below:
          </p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Title>
            We’re sorry, unfortunately you do not have enough eligible credit to
            continue.
          </Title>
          <p className="credit-select-info">
            Our credit relief program requires a minimum of <b>$7,500</b> in
            eligible credit lines in order to join.
          </p>
        </React.Fragment>
      )}

      {approved.length > 0 ? (
        <div className={`credit-box ${nonQualified ? "danger" : "approved"}`}>
          <div className="credit-box-title">
            <div className="circle">
              {nonQualified ? <IconAlertTriangle /> : <IconCheck />}
            </div>
            <Header type="display" size="5">
              Eligible credit lines
            </Header>
          </div>
          {nonQualified && (
            <div className={`alert-box`}>
              You do not have enough eligible credit available to continue with
              the relief program.
            </div>
          )}
          {notEnough && (
            <div className={`alert-box danger`}>
              Please select a minimum of $7,500 of your eligible credit lines to
              unlock your savings.
            </div>
          )}
          <div className="credit-list">
            {approved.sort(listSort).map((c, i) => (
              <AccountBox
                key={`abox-${i}`}
                type="approved"
                data={c.tradeline}
                selected={tradelines.includes(c.id)}
                toggleTradeline={() => toggleTradeline(c.id)}
              />
            ))}
          </div>
          <Text className="approved-desc">
            <b>
              {tradelines?.length} of {approved?.length}
            </b>{" "}
            credit lines selected. <b>Total {flatMoney(approvedBalance)}</b>
          </Text>
        </div>
      ) : null}

      {declined.length > 0 ? (
        <div className="credit-box declined">
          <div className="credit-box-title">
            <div className="circle">
              <IconX />
            </div>
            <Header type="display" size="5">
              Ineligible credit lines
            </Header>
          </div>
          <Text className="declined-desc">
            The following credit lines are not supported by our relief programs.
            Ineligible includes secured debts like mortgages, car loans &
            student loans.
          </Text>

          {showDeclined && (
            <div className="credit-list">
              {declined.sort(listSort).map((c, i) => (
                <AccountBox
                  key={`dbox-${i}`}
                  type="declined"
                  data={c.tradeline}
                />
              ))}
            </div>
          )}
          <Button
            type="secondary"
            onClick={() => setShowDeclined(showDeclined ? false : true)}
          >
            {showDeclined && (
              <React.Fragment>
                Hide ineligible credit <IconChevronUp />
              </React.Fragment>
            )}
            {!showDeclined && (
              <React.Fragment>
                See {declined?.length} ineligible <IconChevronDown />
              </React.Fragment>
            )}
          </Button>
        </div>
      ) : null}

      <Actions
        {...props.actionsProps}
        noPrevious
        noPowered
        showLogo
        form={props.form}
        customNext={
          <div className="credit-select-bottom">
            <Text className="approved-desc">
              <b>
                {tradelines?.length} of {approved?.length}
              </b>{" "}
              credit lines selected. <b>Total {flatMoney(approvedBalance)}</b>
            </Text>
            <Button
              disabled={nonQualified || notEnough}
              loading={saving}
              type="primary"
              size="large"
              block
              className="credit-select-button green-btn"
              onClick={() => handleClick(tradelines)}
            >
              {saving ? (
                "Loading..."
              ) : (
                <span className="unlock-button">
                  <IconLockOpen />
                  Unlock your savings
                </span>
              )}
            </Button>
          </div>
        }
      />
    </div>
  );
}

export default CreditSelect;
