import React, { useState, useEffect } from "react";
import { Search, Table } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { adminAddressBooks } from "App/Admin/_graphql/queries/address_book";
import { getColumns } from "./_data/Columns";
import { getCrumbs } from "../_helpers/crumbs";
import { Button, message, Modal } from "@centrate-io/barn";
import { IconPlus } from "@tabler/icons-react";
import ManageAddress from "./_modals/ManageAddress/ManageAddress";
import ManageBook from "./_drawers/ManageBook/ManageBook";
import { removeAddress } from "App/Admin/_graphql/mutations/address_book";
import { getErrors } from "_helpers/api";
import "./AddressBook.scss";

const defaultParams = {
  results: 10,
  page: 1,
  sortField: "name",
  sortOrder: "ascend",
};

function AddressBook(props) {
  const client = useApolloClient();
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    pagination: { pageSize: 10, showSizeChanger: false },
  });
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [manageModal, setManageModal] = useState(false);
  const [manageDrawer, setManageDrawer] = useState(false);
  const [reset, setReset] = useState(true);
  const [hoveredRowKey, setHoveredRowKey] = useState(null);

  // Load Payments
  const fetchAddresses = async (params = defaultParams) => {
    const queryData = await client.query({
      variables: {
        id: props?.applicationId,
        ...params,
        search: search,
      },
      query: adminAddressBooks,
    });
    const pagination = { ...state.pagination };
    pagination.total = queryData?.data?.adminAddressBooks?.pageTotal;
    pagination.totalAll = queryData?.data?.adminAddressBooks?.total;
    setPage(params?.page || 1);
    props?.setCurrentCount(pagination.totalAll);
    setState({
      loading: false,
      data: queryData?.data?.adminAddressBooks?.data || [],
      pagination: pagination,
    });
  };

  // Remove Bank Account
  const deleteAddress = async (addressBookId) => {
    Modal.confirm({
      title: "Are you sure you want remove this Address?",
      okText: "Remove Address",
      content: (
        <p>
          <b style={{ fontWeight: 600, color: "#000" }}>Warning:</b>
          <br />
          This will REMOVE the address and ALL CONTACTS related to this address
          as well.
          <br />
          <br />
          <b style={{ fontWeight: 600, color: "#000" }}>
            This cannot be undone!
          </b>
        </p>
      ),
      onOk: async () => {
        try {
          await client.mutate({
            variables: {
              address_book_id: addressBookId,
            },
            mutation: removeAddress,
          });
          message.success(`Address successfully removed`);
          fetchAddresses();
        } catch (err) {
          message.error(getErrors(err) || "Error removing address, try again");
        }
      },
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...state.pagination };
    pager.current = pagination.current;
    setState({ pagination: pager });
    fetchAddresses({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      search: search,
    });
  };

  useEffect(() => {
    props?.setCurrentCount(0);
    props.setCrumbs(getCrumbs("address-book"));
    fetchAddresses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startResults = (page - 1) * defaultParams?.results + 1;
  const endResults = startResults + state?.data?.length - 1;

  const showDrawer = (addressId) => {
    setReset(true);
    setTimeout(() => {
      setReset(false);
      setManageDrawer(addressId);
    }, 1);
  };
  const columns = getColumns(
    showDrawer,
    setManageModal,
    deleteAddress,
    hoveredRowKey,
  );

  return (
    <div className="admin-application-address-book">
      {manageModal ? (
        <ManageAddress
          addressId={manageModal}
          reload={fetchAddresses}
          removeModal={() => setManageModal(false)}
        />
      ) : null}
      {!reset ? (
        <ManageBook
          addressId={manageDrawer}
          onClose={() => setManageDrawer(false)}
          open={manageDrawer}
          reload={fetchAddresses}
        />
      ) : null}
      <div className="admin-search admin-search-row">
        <Search
          placeholder="Search address book..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onSearch={() => {
            fetchAddresses({ ...defaultParams, search });
          }}
          style={{ width: 300 }}
        />
        <div className="admin-search-sep" />
        <Button
          onClick={() => setManageModal("new")}
          type="primary"
          className="boxed"
          size="small"
        >
          <IconPlus />
          New Address
        </Button>
      </div>
      <div className="admin-table application-table">
        <Table
          columns={columns}
          loading={state.loading}
          pagination={state.pagination}
          onChange={handleTableChange}
          rowKey={(record) => record.id}
          dataSource={state?.data || []}
          showSorterTooltip={false}
          onRow={(record) => ({
            onMouseEnter: () => setHoveredRowKey(record.id), // Set the hovered row key
            onMouseLeave: () => setHoveredRowKey(null), // Reset the hovered row key
          })}
          scroll={{
            y: "calc(100vh - 317px)",
            x: "max-content",
          }}
          locale={{
            emptyText: state.loading
              ? "Loading addresses..."
              : "No addresses found",
          }}
        />
      </div>
      {state?.data?.length > 0 && (
        <div className="admin-pagination">
          Showing {startResults}-{endResults} of {state?.pagination?.total || 0}{" "}
        </div>
      )}
    </div>
  );
}

export default AddressBook;
