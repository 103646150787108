import { Text, Tooltip } from "@centrate-io/barn";
import {
  IconCalendar,
  IconClipboardCheck,
  IconProgressAlert,
  IconCheck,
  IconUser,
  IconRotateClockwise2,
  IconCategory,
  IconClipboard,
  IconTrash,
} from "@tabler/icons-react";
import { Tag, TaskBadge, TeamAvatar, UserAvatar } from "_styleguide";
import moment from "moment";
import { TableActions } from "_styleguide";

export const getColumns = (
  removeCustomerTask,
  setCompleteModal,
  integrations,
) => {
  return [
    {
      title: (
        <div className="column-name">
          <IconClipboardCheck />
          Task
        </div>
      ),
      dataIndex: "title",
      key: "title",
      sorter: true,
      width: 300,
      render: (title, data) => (
        <div>
          <Text className="doc-name">
            {data.completed ? <IconClipboardCheck /> : <IconClipboard />}
            <b>{data?.title}</b>
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>ID: {data?.short_id}</i>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconCategory />
          Type
        </div>
      ),
      dataIndex: "type",
      key: "type",
      sorter: true,
      width: 160,
      render: (type) => (
        <div className="table-task-badge">
          <TaskBadge full="true" type={type} />
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconProgressAlert />
          Status
        </div>
      ),
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (st, task) => {
        const dueDays = task.due_days ? Math.abs(task.due_days) : null;
        let status = <Tag type="primary">Due now</Tag>;
        if (task.skipped) {
          status = (
            <Tag type="neutral">
              <IconRotateClockwise2 />
              Skipped
            </Tag>
          );
        } else if (task.completed) {
          status = (
            <Tag type="success">
              <IconCheck />
              Completed
            </Tag>
          );
        } else if (task.due_days !== null && task.due_days < 0) {
          status = (
            <Tag type="warning">{`Due ${dueDays} day${dueDays === 1 ? "" : "s"} ago`}</Tag>
          );
        } else if (task.due_days !== null && task.due_days > 0) {
          status = (
            <Tag>{`Due in ${dueDays} day${dueDays === 1 ? "" : "s"}`}</Tag>
          );
        } else if (!task.due_timestamp && task.start === "previous") {
          status = <Tag>Upcoming</Tag>;
        }
        return <div className="table-status-badge">{status}</div>;
      },
    },

    {
      title: (
        <div className="column-name">
          <IconUser />
          Assignee/Completed by
        </div>
      ),
      dataIndex: "assignee",
      key: "assignee",
      render: (assignee, task) =>
        task?.completed_user?.id ? (
          <div className="task-assignee-table">
            <Tooltip title={`Completed by ${task?.completed_user?.name}`}>
              <UserAvatar name={task?.completed_user?.name} size="small" />
              {task?.completed_user?.name}
            </Tooltip>
          </div>
        ) : (
          <div className="task-assignee-table">
            {task.assigned_user && !task?.completed_user?.id ? (
              <Tooltip
                title={`Assigned to ${task?.assigned_user?.name} ${task.assigned_team ? `(${task.assigned_team?.name})` : ""}`}
              >
                <UserAvatar name={task?.assigned_user?.name} size="small" />
                {task?.assigned_user?.name}{" "}
                {task.assigned_team ? `(${task.assigned_team?.name})` : ""}
              </Tooltip>
            ) : null}
            {task.assigned_team &&
            !task?.completed_user?.id &&
            !task?.assigned_user ? (
              <Tooltip title={`Assigned to ${task.assigned_team?.name}`}>
                <TeamAvatar name={task.assigned_team?.name} />
                {task.assigned_team?.name}
              </Tooltip>
            ) : null}
          </div>
        ),
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Due
        </div>
      ),
      dataIndex: "due_timestamp",
      key: "due_timestamp",
      sorter: true,
      defaultSortOrder: "ascend",
      width: 220,
      render: (due_timestamp, data) =>
        !due_timestamp && data.estimated_due_timestamp ? (
          <Text>
            Est.{" "}
            {moment(parseInt(data.estimated_due_timestamp)).format("M/D/YYYY")}
          </Text>
        ) : (
          <Text>
            {!due_timestamp ? (
              <i>n/a</i>
            ) : (
              moment(parseInt(due_timestamp)).format("M/D/YYYY")
            )}
          </Text>
        ),
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Completed/Skipped
        </div>
      ),
      dataIndex: "completed_timestamp",
      key: "completed_timestamp",
      sorter: true,
      width: 220,
      render: (completed_timestamp) => (
        <Text>
          {!completed_timestamp ? (
            <i>n/a</i>
          ) : (
            moment(parseInt(completed_timestamp)).format("M/D/YYYY @ h:mm a")
          )}
        </Text>
      ),
    },
    {
      title: <div className="column-name">&nbsp;</div>,
      dataIndex: "manage",
      key: "manage",
      sorter: false,
      width: 60,
      fixed: "right",
      hidden: true,
      render: (url, task) => {
        const showComplete =
          !task.completed_timestamp &&
          (task.type === "general" ||
            (task.type === "call" && !integrations?.call) ||
            (task.type === "text" && !integrations?.call) ||
            (task.type === "email" && !integrations?.call));

        const items = [];
        if (showComplete) {
          items.push({
            label: (
              <span>
                <IconCheck /> Complete Task
              </span>
            ),
            onClick: () => setCompleteModal(task.id),
          });
        }
        items.push({
          label: (
            <span className="bad">
              <IconTrash /> Remove Task
            </span>
          ),
          onClick: () => removeCustomerTask(task.id),
        });

        return <TableActions items={items} />;
      },
    },
  ];
};
