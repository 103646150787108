export const defaultColorMap = {
  "#E11D48": "#FFFFFF",
  "#EC4899": "#FFFFFF",
  "#8B5CF6": "#FFFFFF",
  "#3B82F6": "#FFFFFF",
  "#67E8F9": "#000000",
  "#0D9488": "#FFFFFF",
  "#7DC240": "#FFFFFF",
  "#F59E0B": "#FFFFFF",
  "#F97316": "#FFFFFF",
  "#6C786D": "#FFFFFF",
  "#B8A383": "#FFFFFF",
  "#000000": "#FFFFFF",
  "#FBCFE8": "#9D174D",
  "#DDD6FE": "#5B21B6",
  "#DBEAFE": "#1D4ED8",
  "#99F6E4": "#115E59",
  "#B6E490": "#30471D",
  "#FCD34D": "#92400E",
  "#FED7AA": "#C2410C",
  "#E5DFD0": "#3B352B",
};
