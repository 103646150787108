import React, { useState } from "react";
import { Avatar, Title } from "_styleguide";
import { Button, message } from "@centrate-io/barn";
import { IconLock } from "@tabler/icons-react";
import PaymentDate from "./_components/PaymentDate/PaymentDate";
import Plaid from "./_components/Plaid/Plaid";
import Cart from "./_components/Cart/Cart";
import { InfoBox, Trust, DueToday, Creating } from "./_components";
import { Actions } from "_styleguide";
import { timeout } from "_assets/js/helpers";
import { createSubscription } from "_graphql/mutations/application";
import { useNavigate } from "react-router-dom";
import { getErrors } from "_helpers/api";
import ConfirmPopup from "./_components/ConfirmPopup/ConfirmPopup";
import "./Checkout.scss";

function Checkout(props) {
  const navigate = useNavigate();
  const { form } = props;
  const [saving, setSaving] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [showWindow, setShowWindow] = useState(false);

  const showCart = form?.plaid && form?.plan?.start_date ? true : false;
  const saveSubscription = async (form, base64PDF) => {
    let formResult = null;
    try {
      formResult = await props.client.mutate({
        variables: { application_id: form.id, pdf_content: base64PDF },
        mutation: createSubscription,
      });
    } catch (err) {
      formResult = null;
      message.error(getErrors(err) || "Error subscribing, try again");
    }
    return formResult ? true : false;
  };
  const startSubscription = async (base64PDF) => {
    setSaving(true);
    await timeout(1000);
    const savedSubscription = await saveSubscription(form, base64PDF);
    if (savedSubscription) {
      navigate(props?.next);
    } else {
      setSaving(false);
    }
  };

  const openWindow = () => setShowWindow(true);
  const closeWindow = () => setShowWindow(false);

  const hasStartDate = form?.plan?.start_date ? true : false;

  return saving ? (
    <Creating />
  ) : (
    <React.Fragment>
      {showWindow ? (
        <ConfirmPopup
          form={form}
          startSubscription={startSubscription}
          closeWindow={closeWindow}
          setSaving={setSaving}
          saving={saving}
        />
      ) : null}

      {!showCart ? (
        <div className="step-start step-final">
          <Avatar icon={<IconLock />} />
          <Title>Last Step to Free up Your Credit!</Title>
          <p className="final-info">
            We just need some basic bank account details to get started. Don't
            worry, <b>nothing will be charged yet.</b> We’re only saving your
            information for future payouts.
          </p>
          <div className="app-form">
            <PaymentDate {...props} setToggle={setToggle} toggle={toggle} />
            <InfoBox plan={form?.plan} />
            <Actions
              {...props.actionsProps}
              noPrevious
              noPowered
              form={props.form}
              customNext={
                <div className="final-actions">
                  <DueToday />
                  <Plaid
                    {...props}
                    hasStartDate={hasStartDate}
                    toggle={toggle}
                    fromRegistration={true}
                  />
                  <Trust />
                </div>
              }
            />
          </div>
        </div>
      ) : (
        <div className="step-start step-final step-review">
          <Avatar icon={<IconLock />} />
          <Title>Let’s Review</Title>
          <p className="final-info">
            You’re almost ready to begin your new life, free of financial
            burden! Take a moment to review your selections.
          </p>
          <div className="app-form">
            <Cart {...props} />
            <Actions
              {...props.actionsProps}
              noPrevious
              noPowered
              form={props.form}
              customNext={
                <div className="final-actions">
                  <DueToday />
                  <Button
                    disabled={saving}
                    type="primary"
                    size="large"
                    block
                    className="plaid-connect green-btn"
                    onClick={openWindow}
                  >
                    Confirm Your Plan
                  </Button>
                </div>
              }
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Checkout;
