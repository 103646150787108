import React, { useState } from "react";
import { Menu } from "antd";
import Contacts from "../Contacts/Contacts";
import "./Navigation.scss";

function Navigation(props) {
  const [tab, setTab] = useState(props?.default || "contacts");

  let items = [
    {
      key: "contacts",
      label: <div className="tab-name">Contacts</div>,
    },
    // {
    //   key: "customers",
    //   label: <div className="tab-name">Customers</div>,
    // },
  ];

  return (
    <>
      <div className="ab-menu">
        <Menu
          mode="horizontal"
          onClick={({ key }) => {
            setTab(key);
          }}
          selectedKeys={[tab]}
          items={items}
        />
      </div>
      <div className="ab-content">
        {tab === "contacts" ? <Contacts addressId={props.addressId} /> : null}
        {tab === "customers" ? <div>Customers</div> : null}
      </div>
    </>
  );
}

export default Navigation;
