export const columnTypes = {
  account: "String",
  address: "String",
  address_line_two: "String",
  agent: "String",
  city: "String",
  co_first_name: "String",
  co_last_name: "String",
  credit_card: "String",
  cvv: "String",
  date_closed: "Date",
  dob: "Date",
  email: "Email",
  expiration: "String",
  first_name: "String",
  half_pay: "String",
  last_name: "String",
  mobile: "Phone",
  monthly_payment: "Money",
  payment_method: "BankType",
  bank_name: "String",
  payments_made: "Integer",
  phone: "Phone",
  plan_bi_weekly_payment: "Money",
  plan_payment: "Money",
  plan_start_date: "Date",
  plan_term: "Integer",
  routing: "String",
  ssn: "SSN",
  state: "String",
  status: "String",
  total_debt: "Money",
  zip: "String",
};
