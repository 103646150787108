import { CompanyAvatar, UserAvatar } from "_styleguide";
import { stripHTML } from "_assets/js/helpers";
import moment from "moment";
import "./Thread.scss";

function Thread(props) {
  const { thread, userData } = props;
  const isUnread = thread?.unread ? true : false;
  return (
    <div
      className={`thread ${props.selected === thread.id ? "selected" : ""} ${isUnread ? "unread" : ""}`}
      onClick={() => props.setSelected(thread.id)}
    >
      {thread.latest_email?.user ? (
        <CompanyAvatar user={userData} />
      ) : (
        <UserAvatar
          name={
            thread.latest_email?.from?.name || thread.latest_email?.from?.email
          }
        />
      )}
      <div className="thread-info">
        <h5>
          {thread.latest_email?.from?.name || thread.latest_email?.from?.email}
        </h5>
        <p className="thread-subject">
          {thread.latest_email?.subject || thread.subject}
        </p>
        <p className="thread-preview">
          {stripHTML(thread.latest_email?.body || "")}
        </p>
      </div>
      <div className="thread-date">
        {moment(parseInt(thread.updatedAt)).format("MMM D")}
      </div>
    </div>
  );
}

export default Thread;
