import React, { useState, useEffect } from "react";
import { Table, Search } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { getColumns } from "./_data/Columns";
import { getAddressBookContacts } from "App/Admin/_graphql/queries/address_book";
import { IconPlus } from "@tabler/icons-react";
import { Button, Modal, message } from "@centrate-io/barn";
import ManageContact from "../../_modals/ManageContact/ManageContact";
import { removeAddressContact } from "App/Admin/_graphql/mutations/address_book";
import { getErrors } from "_helpers/api";
import "./Contacts.scss";

function Contacts(props) {
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState();
  const [manageModal, setManageModal] = useState(false);
  const [hoveredRowKey, setHoveredRowKey] = useState(null);

  const fetchContacts = async () => {
    const queryData = await client.query({
      variables: { address_book_id: props?.addressId },
      query: getAddressBookContacts,
    });
    setLoading(false);
    setContacts(queryData?.data?.getAddressBookContacts);
  };

  // Remove Bank Account
  const deleteContact = async (addressContactId) => {
    Modal.confirm({
      title: "Are you sure you want remove this Contact?",
      okText: "Remove Contact",
      content: (
        <p>
          <b style={{ fontWeight: 600, color: "#000" }}>
            Warning: This cannot be undone!
          </b>
        </p>
      ),
      onOk: async () => {
        try {
          await client.mutate({
            variables: {
              address_contact_id: addressContactId,
            },
            mutation: removeAddressContact,
          });
          message.success(`Contact successfully removed`);
          fetchContacts();
        } catch (err) {
          message.error(getErrors(err) || "Error removing contact, try again");
        }
      },
    });
  };

  useEffect(() => {
    fetchContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let tableData = (contacts || []).filter((u) => {
    if ((search?.length || 0) <= 0) return true;
    const nameSearch = u.name?.toLowerCase();
    const addressSearch = u.address?.toLowerCase();
    const emailSearch = u.email?.toLowerCase();
    const phoneSearch = u.phone?.toLowerCase();
    return (
      nameSearch.includes(search) ||
      emailSearch?.includes(search) ||
      phoneSearch?.includes(search) ||
      addressSearch.includes(search)
    );
  });

  const columns = getColumns(setManageModal, deleteContact, hoveredRowKey);

  return (
    <div className="ab-contacts">
      {manageModal ? (
        <ManageContact
          contactId={manageModal}
          addressId={props?.addressId}
          reload={fetchContacts}
          removeModal={() => setManageModal(false)}
        />
      ) : null}
      <div className="ab-manage">
        <div className="ab-actions">
          <Search
            placeholder="Search contacts..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ width: 240 }}
          />
        </div>
        <div className="ab-filters">
          <Button
            type="blanco"
            className="boxed"
            size="small"
            onClick={() => setManageModal("new")}
          >
            <IconPlus />
            Add Contact
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        loading={loading}
        pagination={{ pageSize: 5, showSizeChanger: false }}
        rowKey={(record) => record.id}
        dataSource={tableData}
        showSorterTooltip={false}
        onRow={(record) => ({
          onMouseEnter: () => setHoveredRowKey(record.id), // Set the hovered row key
          onMouseLeave: () => setHoveredRowKey(null), // Reset the hovered row key
        })}
        scroll={{
          y: "calc(80vh - 231px)",
          x: "max-content",
        }}
        locale={{
          emptyText: loading ? "Loading contacts..." : "No contacts found",
        }}
      />
    </div>
  );
}

export default Contacts;
