import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { updatePayment } from "_graphql/mutations/payment";
import { getErrors } from "_helpers/api";
import { IconEdit } from "@tabler/icons-react";
import { InputLabel, Flex, DatePicker, Tag, Select } from "_styleguide";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import { paymentTag } from "_helpers/payments";
import "./EditPayment.scss";

dayjs.extend(dayjsPluginUTC);

function EditPayment(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const payment = props.payment;

  const formatInitialDate = (dateFrom) => {
    let nextValue = undefined;
    if (dateFrom) {
      if (dateFrom?.length <= 13) {
        nextValue = dayjs(parseInt(dateFrom)).utc();
      } else {
        nextValue = dayjs(dateFrom).utc();
      }
      return nextValue;
    } else {
      return undefined;
    }
  };
  const [nextAttempt, setNextAttempt] = useState(
    formatInitialDate(props.payment?.next_attempt),
  );
  const [status, setStatus] = useState(props.payment?.status);

  // Submit question form
  const saveCreditLine = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          payment_id: payment.id,
          next_attempt: nextAttempt,
          status: status,
        },
        mutation: updatePayment,
      });
      message.success(`Payment successfully updated`);
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(getErrors(err) || "Error updating payment, try again");
      setSaving(false);
    }
    setSaving(false);
  };

  const dateFormat = "MM/DD/YYYY";
  const dateChange = async (v) => {
    setHasChanged(true);
    setNextAttempt(v);
  };

  return (
    <Modal
      wrapClassName="edit-payment-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      <div className="pp-modal-header">
        <div>
          <IconEdit />
        </div>
        <h3>
          Edit Payment #{payment.order}{" "}
          <span>
            / <i>{payment.short_id}</i>
          </span>
        </h3>
      </div>

      <Modal.Body>
        <Flex vertical={true}>
          <InputLabel htmlFor="next_attempt">Next Attempt *</InputLabel>
          <DatePicker
            value={nextAttempt}
            placeholder="Select date..."
            onChange={(val) => dateChange(val)}
            format={dateFormat}
            allowClear={false}
            size="medium"
          />
        </Flex>
        <Flex vertical={true}>
          <InputLabel htmlFor="status">Status *</InputLabel>
          <Select
            value={status}
            onChange={(newType) => {
              setHasChanged(true);
              setStatus(newType);
            }}
            popupClassName="pp-popup task-status-popup"
            placeholder="Select status..."
            style={{ width: "100%" }}
            size="extra-medium"
          >
            {Object.keys(paymentTag).map((status) => {
              return (
                <Select.Option key={`pt-${status}`} value={status}>
                  <Tag type={paymentTag[status]}>{status?.toUpperCase()}</Tag>
                </Select.Option>
              );
            })}
          </Select>
        </Flex>
      </Modal.Body>

      <Modal.Actions>
        <Button block type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button
          block
          type="primary"
          disabled={!hasChanged}
          onClick={saveCreditLine}
          loading={saving}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default EditPayment;
