import React from "react";
import { numberWithCommas } from "_assets/js/helpers";
import "./StatBar.scss";

function StatBar(props) {
  const { stats } = props;

  return (
    <div className="stat-bar">
      {Object.keys(stats || {})
        .filter((s) => s !== "cancelled")
        .map((key) => (
          <div key={`aps-${key}`} className="stat-card">
            <label>{key}</label>
            <h3>
              {numberWithCommas(stats[key] || 0)} task
              {(stats[key] || 0) === 1 ? "" : "s"}
            </h3>
          </div>
        ))}
    </div>
  );
}

export default StatBar;
