import { Button, Text } from "@centrate-io/barn";
import {
  IconSignature,
  IconCoin,
  IconScale,
  IconCalendar,
  IconFile,
  IconFiles,
  IconLetterCase,
  IconProgressAlert,
  IconTag,
} from "@tabler/icons-react";
import { formatMoney } from "_assets/js/helpers";
import { Tag, SelectTags } from "_styleguide";
import { paymentTag } from "_helpers/payments";
import moment from "moment";

export const getColumns = (setModal) => {
  return [
    {
      title: (
        <div className="column-name">
          <IconFiles />
          Customer
        </div>
      ),
      dataIndex: "application",
      key: "application",
      width: 200,
      render: (application, data) => (
        <div>
          <Text className="doc-name">
            <IconFile />
            <b>
              {data?.application?.first_name} {data?.application?.last_name}
            </b>
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>ID: {data?.application?.short_id}</i>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconSignature />
          Payment
        </div>
      ),
      dataIndex: "order",
      key: "order",
      sorter: true,
      width: 200,
      render: (order, data) => (
        <div>
          <Text className="doc-name">
            <IconCoin />
            <b>
              Payment # {order || 0}/{data?.application?.total_payments}
            </b>
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>{data?.short_id}</i>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconTag />
        </div>
      ),
      dataIndex: "tags",
      hidden: false,
      key: "tags",
      render: (tags, data) => (
        <SelectTags category="payments" paymentId={data.id} tags={tags} />
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconProgressAlert />
          Status
        </div>
      ),
      dataIndex: "status",
      key: "status",
      sorter: true,
      width: 130,
      align: "center",
      render: (status) => (
        <Text>
          <Tag type={paymentTag[status]}>{status?.toUpperCase()}</Tag>
        </Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconLetterCase />
          Reason
        </div>
      ),
      dataIndex: "reason",
      key: "reason",
      width: 180,
      render: (status, data) => {
        let reason = null;
        if (data?.attempts?.length > 0) {
          reason = data.attempts[data.attempts.length - 1];
        }
        return data?.status === "failed" || data?.status === "paused" ? (
          <Text>{data.sub_status || reason?.description || ""}</Text>
        ) : null;
      },
    },
    {
      title: (
        <div className="column-name">
          <IconScale />
          Amount
        </div>
      ),
      dataIndex: "amount",
      key: "amount",
      sorter: true,
      width: 150,
      align: "center",
      render: (amount) => <Text>{formatMoney(amount)}</Text>,
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          First Attempt
        </div>
      ),
      dataIndex: "first_attempt",
      key: "first_attempt",
      sorter: true,
      defaultSortOrder: "ascend",
      width: 180,
      align: "center",
      render: (first_attempt) => (
        <Text>{moment(parseInt(first_attempt)).utc().format("M/D/YYYY")}</Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Next Attempt
        </div>
      ),
      dataIndex: "next_attempt",
      key: "next_attempt",
      sorter: true,
      width: 180,
      align: "center",
      render: (next_attempt) => (
        <Text>{moment(parseInt(next_attempt)).utc().format("M/D/YYYY")}</Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Last Attempt
        </div>
      ),
      dataIndex: "last_attempt",
      key: "last_attempt",
      sorter: true,
      width: 180,
      align: "center",
      render: (last_attempt, data) => {
        return (
          <Text>
            {last_attempt ? (
              moment(parseInt(last_attempt)).utc().format("M/D/YYYY")
            ) : (
              <i>n/a</i>
            )}
          </Text>
        );
      },
    },
    {
      title: <div className="column-name">&nbsp;</div>,
      dataIndex: "manage",
      key: "manage",
      width: 100,
      align: "center",
      fixed: "right",
      render: (address, data) => (
        <div className="table-actions">
          <Button
            type="secondary"
            size="small"
            onClick={() => setModal(data.id)}
            disabled={data?.attempts?.length <= 0 && data?.updates?.length <= 0}
          >
            History
          </Button>
        </div>
      ),
    },
  ];
};
