import React, { createContext, useContext, useEffect, useState } from "react";
import { getTags, getTagCategories } from "App/Admin/_graphql/queries/tag";
const TagsContext = createContext();

// Create a provider component
export const TagsProvider = ({ children, client }) => {
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);

  // Load Data
  const fetchTags = async () => {
    const queryData = await client.query({
      query: getTags,
    });
    setTags(queryData?.data?.getTags || []);
  };
  const fetchCategories = async () => {
    const queryData = await client.query({
      query: getTagCategories,
    });
    setCategories(queryData?.data?.getTagCategories || []);
  };

  useEffect(() => {
    fetchTags();
    fetchCategories();
  }, []);

  return (
    <TagsContext.Provider
      value={{
        tags,
        setTags,
        categories,
        setCategories,
        reloadTags: fetchTags,
        reloadCategories: fetchCategories,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
};

// Custom hook to use the context
export const useTags = () => {
  return useContext(TagsContext);
};
