import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Start from "./Start/Start";
import Contact from "./Contact/Contact";
import Location from "./Location/Location";
import Phone from "./Phone/Phone";
import Checkout from "./Checkout/Checkout";
import Success from "./Success/Success";
import Invalid from "./Invalid/Invalid";
import Credit from "./Credit/Credit";
import Offers from "./Offers/Offers";
import PayPathLogo from "_assets/logos/icon.svg";
import { getApplicationForm } from "_helpers/form";
import { FormatPhone } from "_assets/js/helpers";
import { ScrollToTop, Loading } from "_styleguide";
import "./Application.scss";

function Application(props) {
  const navigate = useNavigate();
  const [form, setForm] = useState();
  const [audit, setAudit] = useState();
  const [bank, setBank] = useState();
  const [paymentEntryType, setPaymentEntryType] = useState();
  const updateForm = (f, v) => {
    const n = { ...form };
    n[f] = f === "phone" ? FormatPhone(v) : v;
    setForm(n);
  };
  const updateAddress = (values) => {
    setForm({ ...form, ...values });
  };

  const updateBank = async () => {
    let formData = await getApplicationForm(props.client);
    if (formData?.currentForm) {
      setBank(formData?.bank);
    }
  };

  let location = useLocation();
  const path = location?.pathname;

  const flow = {
    "/": {
      name: "start",
      progress: 0,
      next: "/contact",
      previous: null,
    },
    "/contact": {
      name: "contact",
      progress: 0,
      next: "/location",
      previous: "/",
    },
    "/location": {
      name: "location",
      progress: 30,
      next: "/info",
      previous: "/contact",
    },
    "/info": {
      name: "phone",
      progress: 60,
      next: "/credit",
      previous: "/location",
    },
    "/credit": {
      name: "credit",
      progress: 75,
      next: "/offers",
      previous: "/contact",
    },
    "/offers": {
      name: "offers",
      progress: 85,
      next: "/checkout",
      previous: "/credit",
    },
    "/checkout": {
      name: "checkout",
      progress: 95,
      next: "/success",
      previous: "/credit",
    },
    "/success": {
      name: "success",
      progress: 100,
      next: null,
      previous: "/checkout",
    },
  };

  const pageProps = {
    ...flow[path],
    form,
    audit,
    setForm,
    paymentEntryType,
    bank,
    updateBank,
    u: updateForm,
    ua: updateAddress,
    client: props.client,
  };

  /* Load initial form data */
  useEffect(() => {
    const getInitialForm = async () => {
      let formData = await getApplicationForm(props.client);
      setForm(formData?.currentForm);
      setAudit(formData?.audit);
      setBank(formData?.bank);
      setPaymentEntryType(formData.paymentEntryType || "plaid");
      if (formData?.goHome !== null) {
        navigate(formData?.goHome);
      } else if (
        formData?.goHome === null &&
        formData?.currentForm?.id &&
        path === "/"
      ) {
        navigate("/contact");
      }
    };
    getInitialForm();
  }, [props.client, navigate]); // eslint-disable-line react-hooks/exhaustive-deps

  return !form ? (
    <Loading />
  ) : (
    <React.Fragment>
      <div className="main-logo-container">
        <img className="main-logo" src={PayPathLogo} alt="PayPath" />
      </div>
      <div id="app-scroll" className={`app-box`}>
        <ScrollToTop />
        <Routes>
          <Route
            exact
            path="/"
            id="start"
            element={<Start {...props} {...pageProps} />}
          />
          <Route
            exact
            path="/contact"
            id="contact"
            element={<Contact {...props} {...pageProps} />}
          />
          <Route
            exact
            path="/location"
            id="location"
            element={<Location {...props} {...pageProps} />}
          />
          <Route
            exact
            path="/info"
            id="phone"
            element={<Phone {...props} {...pageProps} />}
          />
          <Route
            exact
            path="/checkout"
            id="checkout"
            element={<Checkout {...props} {...pageProps} />}
          />
          <Route
            exact
            path="/credit"
            id="credit"
            element={<Credit {...props} {...pageProps} />}
          />
          <Route
            exact
            path="/offers"
            id="offers"
            element={<Offers {...props} {...pageProps} />}
          />
          <Route
            exact
            path="/success"
            id="success"
            element={<Success {...props} {...pageProps} />}
          />
          <Route
            exact
            path="/invalid"
            id="invalid"
            element={<Invalid {...props} {...pageProps} />}
          />
        </Routes>
      </div>
    </React.Fragment>
  );
}

export default Application;
