import React, { useState, useEffect } from "react";
import { AutoComplete, Input } from "antd";
import { useApolloClient } from "@apollo/client";
import { searchTasks } from "App/Admin/_graphql/queries/task";
import { TaskBadge } from "_styleguide";

const TaskSearch = (props) => {
  const client = useApolloClient();
  const [options, setOptions] = useState([]);
  const [tasks, setTasks] = useState([]);

  const fetchTasks = async () => {
    const queryData = await client.query({
      variables: { type: props.type || null },
      query: searchTasks,
    });
    let todoTasks = queryData?.data?.getMyTasks?.todo || [];
    generateOptions(todoTasks);
    setTasks(todoTasks);
  };

  const handleSearch = (value) => {
    if (value?.length <= 0) generateOptions(tasks);
    const searchValue = value?.toLowerCase() || "";
    const filteredTasks = tasks.filter((t) => {
      return t.title?.toLowerCase().includes(searchValue);
    });
    generateOptions(filteredTasks);
  };

  const generateOptions = (list) => {
    const taskOptions = list.map((t) => ({
      value: t.id,
      label: (
        <span>
          <TaskBadge type={t.type} />
          {t.title} - {t.application?.first_name} {t.application?.last_name}
        </span>
      ),
    }));
    setOptions(taskOptions);
  };

  const onSelect = (task_id) => {
    const taskData = tasks?.find((t) => t.id === task_id);
    props.onSelect(taskData.application, taskData);
  };

  useEffect(() => {
    fetchTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AutoComplete
      options={options}
      onSearch={handleSearch}
      onSelect={onSelect}
      placeholder={`Search due ${props.type || ""} tasks`}
      popupClassName="pp-select-dropdown ppsd-task"
      className="pp-select"
    >
      <Input.Search />
    </AutoComplete>
  );
};

export default TaskSearch;
