import { Text } from "@centrate-io/barn";
import { CustomTag, TableActions } from "_styleguide";
import { IconTrash, IconPencil } from "@tabler/icons-react";
import moment from "moment";

export const getColumns = (setManageModal, removeTag, readOnly) => {
  return [
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
      render: (key) => (
        <Text>
          <b>{key}</b>
        </Text>
      ),
    },
    {
      title: "Preview",
      dataIndex: "preview",
      key: "preview",
      render: (preview, tag) => (
        <CustomTag
          backgroundColor={tag.background_color}
          fontColor={tag.font_color}
          tooltip={tag.tooltip}
        >
          {tag.description || "Tag"}
        </CustomTag>
      ),
    },
    {
      title: "Tooltip",
      dataIndex: "tooltip",
      key: "tooltip",
      render: (tooltip) => <Text>{tooltip}</Text>,
    },
    {
      title: "Category",
      dataIndex: "tag_category",
      key: "tag_category",
      render: (tag_category) => (
        <Text className="tag-category">
          {tag_category ? <b>{tag_category}</b> : <i>n/a</i>}
        </Text>
      ),
    },

    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => (
        <Text>{moment(createdAt * 1).format("MMM D, YYYY")}</Text>
      ),
    },
    {
      title: <div className="column-name">&nbsp;</div>,
      dataIndex: "manage",
      key: "manage",
      sorter: false,
      width: 60,
      fixed: "right",
      render: (url, data) => {
        const items = [
          {
            label: (
              <span>
                <IconPencil /> Manage Tag
              </span>
            ),
            onClick: () => setManageModal(data.id),
          },
        ];
        if (!readOnly) {
          items.push({
            label: (
              <span className="bad">
                <IconTrash /> Remove Tag
              </span>
            ),
            onClick: () => removeTag(data.id),
          });
        }
        return <TableActions items={items} />;
      },
    },
  ];
};
