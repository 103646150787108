import {
  getApplication,
  getCheckoutApplication,
} from "_graphql/queries/application";

const formStructure = {
  first_name: undefined,
  last_name: undefined,
  email: undefined,
  phone: undefined,
  ssn_last_4: undefined,
  address: undefined,
  address_line_two: undefined,
  city: undefined,
  state: undefined,
  zip: undefined,
  plaid: undefined,
  stage: undefined,
  plan: {
    type: undefined,
    amount: undefined,
    service_percent: undefined,
    monthly_fees: undefined,
    debt_amount: undefined,
    months: undefined,
    start_date: undefined,
  },
};

export const hasCategory = () => {
  return formStructure ? true : false;
};

export const getForm = async (apolloClient) => {
  let currentForm = undefined;
  let goHome = null;
  const hasQualified = localStorage.getItem("pp_qualify");

  // Attempt to grab form data or use blank form
  try {
    const formId = JSON.parse(localStorage.getItem("pp_form"));
    if (formId) {
      const result = await apolloClient.query({
        query: getApplication,
        variables: {
          id: formId,
        },
      });
      if (result?.data?.getApplication) {
        currentForm = result?.data?.getApplication;
      } else {
        goHome = "/";
        localStorage.removeItem("pp_form");
        currentForm = Object.assign({}, formStructure);
      }
    } else {
      currentForm = Object.assign({}, formStructure);
      goHome = "/";
    }
  } catch (err) {
    console.log(err);
    localStorage.removeItem("pp_form");
    currentForm = Object.assign({}, formStructure);
    goHome = "/";
  }

  if (goHome !== null && hasQualified === "yes") goHome = "/contact";
  if (currentForm.ssn_verified === false) goHome = "/invalid";
  if (currentForm.status === "subscription") goHome = "/success";
  const audit = { ...currentForm.audit };
  delete currentForm.status;
  delete currentForm.ssn_verified;
  delete currentForm.audit;

  // Return entire form or just a part depending on what is requested
  return {
    currentForm,
    goHome,
    audit,
  };
};

export const getRegistrationForm = async (apolloClient, applicationShortId) => {
  let currentForm = undefined;
  try {
    const result = await apolloClient.query({
      query: getCheckoutApplication,
      variables: {
        short_id: applicationShortId,
      },
    });
    currentForm = result?.data?.getApplication;
  } catch (err) {
    console.log(err);
    currentForm = undefined;
  }

  const status = currentForm?.status;
  const audit = { ...currentForm.audit };
  const bank = currentForm.bank ? { ...currentForm.bank } : null;
  const paymentEntryType = currentForm?.payment_entry_type;
  delete currentForm.status;
  delete currentForm.ssn_verified;
  delete currentForm.audit;
  delete currentForm.payment_entry_type;
  delete currentForm.bank;

  // Return entire form or just a part depending on what is requested
  return {
    currentForm,
    status,
    audit,
    bank,
    paymentEntryType,
  };
};

export const getApplicationForm = async (apolloClient) => {
  let currentForm = undefined;
  let goHome = null;
  const hasQualified = localStorage.getItem("pp_qualify");

  // Attempt to grab form data or use blank form
  try {
    const formId = JSON.parse(localStorage.getItem("pp_form"));
    if (formId) {
      const result = await apolloClient.query({
        query: getCheckoutApplication,
        variables: {
          id: formId,
        },
      });
      if (result?.data?.getApplication) {
        currentForm = result?.data?.getApplication;
      } else {
        goHome = "/";
        localStorage.removeItem("pp_form");
        currentForm = Object.assign({}, formStructure);
      }
    } else {
      currentForm = Object.assign({}, formStructure);
      goHome = "/";
    }
  } catch (err) {
    console.log(err);
    localStorage.removeItem("pp_form");
    currentForm = Object.assign({}, formStructure);
    goHome = "/";
  }

  if (goHome !== null && hasQualified === "yes") goHome = "/contact";
  if (currentForm.ssn_verified === false) goHome = "/invalid";
  if (currentForm.status === "subscription") goHome = "/success";
  const audit = { ...currentForm.audit };
  const bank = currentForm.bank ? { ...currentForm.bank } : null;
  const paymentEntryType = currentForm?.payment_entry_type;
  delete currentForm.status;
  delete currentForm.ssn_verified;
  delete currentForm.audit;
  delete currentForm.payment_entry_type;
  delete currentForm.bank;

  // Return entire form or just a part depending on what is requested
  return {
    currentForm,
    goHome,
    audit,
    bank,
    paymentEntryType,
  };
};

export const saveFormLocal = (applicationId) => {
  localStorage.setItem("pp_form", JSON.stringify(applicationId));
};
