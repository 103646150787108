import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { adminApplication } from "../_graphql/queries/application";
import {
  IconFile,
  IconClockPause,
  IconDots,
  IconPencil,
  IconCircleX,
  IconClockPlay,
} from "@tabler/icons-react";
import { Loading, Tag } from "_styleguide";
import { Dropdown } from "antd";
import Navigation from "./_components/Navigation/Navigation";
import { Button, message } from "@centrate-io/barn";
import PausePlan from "./_modals/PausePlan/PausePlan";
import UnpausePlan from "./_modals/UnpausePlan/UnpausePlan";
import ModifyPlan from "./_modals/ModifyPlan/ModifyPlan";
import CancelPlan from "./_modals/CancelPlan/CancelPlan";
import { appMap } from "_helpers/application";
import { useBar } from "_contexts/useBar";
import "./Application.scss";

function Application(props) {
  const params = useParams();
  const client = useApolloClient();
  const { setCustomer } = useBar();
  const [status, setStatus] = useState();
  const [wasCancelled, setWasCancelled] = useState();
  const [pauseModal, setPauseModal] = useState(false);
  const [unPauseModal, setUnPauseModal] = useState(false);
  const [modifyModal, setModifyModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [unread, setUnread] = useState();

  const [state, setState] = useState({
    loading: true,
    application: undefined,
  });

  // Load Entries
  const fetchApplication = async () => {
    const applicationData = await client.query({
      variables: { id: params?.id },
      query: adminApplication,
    });
    const app = applicationData?.data?.adminApplication;
    if (!app) {
      message.error("Application not found");
      return;
    }
    const status = app.status;
    const appCancelled = app.was_cancelled || false;
    setStatus(app.status || "application");
    setWasCancelled(app.was_cancelled || false);
    setUnread(app.unread || null);
    if (app.status) delete app.status;
    if (app.was_cancelled) delete app.was_cancelled;
    if (app.unread) delete app.unread;

    setState({
      loading: false,
      application: app || undefined,
    });
    setCustomer(app || undefined);
    const actualStatus =
      status === "application" && appCancelled ? "modifying" : status;
    props.setCrumbs({
      title: app.first_name + " " + app.last_name,
      icon: <IconFile />,
      description: <span className="cp">{params.id}</span>,
      tag: (
        <Tag size="small" type={appMap[actualStatus]?.type}>
          {appMap[actualStatus]?.title}
        </Tag>
      ),
    });
  };
  useEffect(() => {
    props.setCurrentCount(undefined);
    props.setCrumbs(undefined);
    fetchApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  const app = state?.application;
  let items = [];
  const wasModified = status === "application" && wasCancelled === true;

  if ((status !== "cancelled" && !wasModified) || status === "paused") {
    items = [
      {
        label: (
          <span>
            <IconPencil /> Modify Plan
          </span>
        ),
        onClick: () => setModifyModal(true),
      },
      {
        label: (
          <span className="bad">
            <IconCircleX /> Cancel Plan
          </span>
        ),
        onClick: () => setCancelModal(true),
      },
    ];
  } else if (status !== "cancelled" && wasModified) {
    items = [
      {
        label: (
          <span className="bad">
            <IconCircleX /> Cancel Plan
          </span>
        ),
        onClick: () => setCancelModal(true),
      },
    ];
  }

  const modalProps = {
    app,
    fetchApplication,
    shortId: params?.id,
    appId: app?.id?.toString(),
    reload: fetchApplication,
  };

  return state?.loading ? (
    <Loading />
  ) : (
    <div className="admin-application">
      {!props.readOnly ? (
        <>
          {pauseModal ? (
            <PausePlan
              {...modalProps}
              removeModal={() => setPauseModal(false)}
            />
          ) : null}
          {unPauseModal ? (
            <UnpausePlan
              {...modalProps}
              removeModal={() => setUnPauseModal(false)}
            />
          ) : null}
          {modifyModal ? (
            <ModifyPlan
              {...modalProps}
              removeModal={() => setModifyModal(false)}
            />
          ) : null}
          {cancelModal ? (
            <CancelPlan
              app={app}
              removeModal={() => setCancelModal(false)}
              reload={fetchApplication}
            />
          ) : null}
        </>
      ) : null}
      <div className="top-border" />
      <Navigation
        default={params?.tab}
        status={status}
        wasModified={wasModified}
        applicationId={app.id}
        appData={app}
        shortId={params.id}
        user={props.user}
        reloadApplication={fetchApplication}
        readOnly={props.readOnly}
        unread={unread}
      />
      {!props.readOnly ? (
        <div className="admin-search admin-button">
          {!["paused", "cancelled", "application"].includes(status) ? (
            <Button
              type="blanco"
              size="small"
              onClick={() => setPauseModal(true)}
            >
              <IconClockPause />
              Pause Plan
            </Button>
          ) : null}
          {status === "paused" ? (
            <Button
              type="blanco"
              size="small"
              onClick={() => setUnPauseModal(true)}
            >
              <IconClockPlay />
              Un-pause Plan
            </Button>
          ) : null}
          {status === "cancelled" ? (
            <Button
              type="blanco"
              size="small"
              onClick={() => setModifyModal(true)}
            >
              <IconPencil />
              Modify Plan
            </Button>
          ) : null}
          {!["application", "cancelled"].includes(status) || wasModified ? (
            <Dropdown
              className="app-actions-drop"
              menu={{
                items,
              }}
              trigger={["click"]}
              overlayClassName="app-actions-drop-drop"
            >
              <Button type="blanco" size="small">
                <IconDots />
              </Button>
            </Dropdown>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default Application;
