import { gql } from "@apollo/client";

export const getTags = gql`
  query {
    getTags {
      id
      key
      background_color
      tag_category
      font_color
      tooltip
      description
      createdAt
    }
  }
`;

export const getTagCategories = gql`
  query {
    getTagCategories {
      id
      name
      updatedAt
      createdAt
    }
  }
`;

export const getTag = gql`
  query getTag($tag_id: ID) {
    getTag(tag_id: $tag_id) {
      key
      background_color
      tag_category
      font_color
      tooltip
      description
    }
  }
`;

export const getTagCategory = gql`
  query getTagCategory($tag_category_id: ID) {
    getTagCategory(tag_category_id: $tag_category_id) {
      name
    }
  }
`;
