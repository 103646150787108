import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, Modal, message } from "@centrate-io/barn";
import { getAddressBook } from "App/Admin/_graphql/queries/address_book";
import { IconAddressBook, IconPlus } from "@tabler/icons-react";
import { getErrors } from "_helpers/api";
import {
  FormInput,
  InputLabel,
  Flex,
  Loading,
  Autocomplete,
  Select,
} from "_styleguide";
import { STATES } from "_helpers/location";
import { validateAddressBook } from "_helpers/validateForm";
import { FormatEXT } from "_assets/js/helpers";
import { saveAddressBook } from "App/Admin/_graphql/mutations/address_book";
import "./ManageAddress.scss";

function ManageAddress(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState();
  const [optional, setOptional] = useState([]);

  const isEditing = props.addressId !== "new";

  const fetchAddress = async () => {
    if (isEditing) {
      const queryData = await client.query({
        variables: { address_book_id: props?.addressId },
        query: getAddressBook,
      });
      setLoading(false);
      setSaving(false);
      let bookData = queryData?.data?.getAddressBook;
      if (bookData?.short_id) delete bookData.short_id;
      setForm(bookData);
    } else {
      setLoading(false);
      setForm({
        name: undefined,
        address_name: undefined,
        address: undefined,
        address_line_two: undefined,
        city: undefined,
        state: undefined,
        zip: undefined,
        website: undefined,
        phone: undefined,
        email: undefined,
      });
    }
  };

  // Submit question form
  const saveForm = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          address_book_id: props.addressId !== "new" ? props.addressId : null,
          address_book: form,
        },
        mutation: saveAddressBook,
      });
      message.success(
        `Address book successfully ${isEditing ? "saved" : "created"}`,
      );
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(
        getErrors(err) ||
          `Error ${isEditing ? "saving" : "creating"} address book, try again`,
      );
      setSaving(false);
    }
    setSaving(false);
  };

  const updateForm = (field, value) => {
    const editedform = { ...form };
    editedform[field] = value;
    setForm(editedform);
  };

  const ua = (oldForm, values) => {
    setForm({ ...oldForm, ...values });
  };

  useEffect(() => {
    fetchAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addOptional = (option) => {
    const uniqueOptional = new Set(optional || []);
    uniqueOptional.add(option);
    setOptional([...uniqueOptional]);
  };

  const validation = validateAddressBook(form);

  const showEmail = optional?.includes("email") || form?.email?.length > 0;
  const showPhone = optional?.includes("phone") || form?.phone?.length > 0;
  const showWebsite =
    optional?.includes("website") || form?.website?.length > 0;

  const showOptions = !showEmail || !showPhone || !showWebsite;

  return (
    <Modal
      wrapClassName="manage-address-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={600}
    >
      {loading ? (
        <Modal.Body>
          <Loading />
        </Modal.Body>
      ) : (
        <>
          <div className="pp-modal-header">
            <div>
              <IconAddressBook />
            </div>
            <h3>{props.editing ? "Edit" : "New"} Address</h3>
          </div>

          <Modal.Body>
            <Flex vertical={true}>
              <InputLabel htmlFor="name" {...validation.name}>
                Name *
              </InputLabel>
              <FormInput
                id="name"
                value={form.name}
                disabled={saving}
                onChange={(e) => updateForm("name", e.target.value)}
                placeholder="e.g. Chase Bank..."
                size="small"
                {...validation.name}
              />
            </Flex>
            <div className="form-item">
              <InputLabel {...validation.address}>Address *</InputLabel>
              <div className="form-row">
                <Autocomplete
                  id="address"
                  value={form.address}
                  onChange={(v) => updateForm("address", v)}
                  form={form}
                  updateAddress={ua}
                  size="small"
                  {...validation.address}
                />
                <FormInput
                  id="address_line_two"
                  value={form.address_line_two}
                  onChange={(e) =>
                    updateForm("address_line_two", e.target.value)
                  }
                  placeholder="APT, suite, unit"
                  size="small"
                  style={{ minWidth: "160px", maxWidth: "160px" }}
                />
              </div>
            </div>

            <div className="form-row">
              <div>
                <InputLabel htmlFor="city" {...validation.city}>
                  City *
                </InputLabel>
                <FormInput
                  id="city"
                  value={form.city}
                  onChange={(e) => updateForm("city", e.target.value)}
                  size="small"
                  {...validation.city}
                />
              </div>
              <div style={{ maxWidth: "160px" }}>
                <InputLabel htmlFor="state" {...validation.state}>
                  State *
                </InputLabel>
                <Select
                  value={form.state}
                  onChange={(val) => updateForm("state", val)}
                  size="medium"
                  popupClassName="pp-select-dropdown"
                  {...validation.state}
                >
                  {STATES.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div style={{ maxWidth: "160px" }}>
                <InputLabel htmlFor="zip" {...validation.zip}>
                  Zip *
                </InputLabel>
                <FormInput
                  id="zip"
                  value={form.zip}
                  onChange={(e) => updateForm("zip", e.target.value)}
                  size="small"
                  maxLength="5"
                  {...validation.zip}
                />
              </div>
            </div>
            <div className="form-gap"></div>
            {showOptions ? (
              <Flex vertical={true}>
                <InputLabel>Add optional information</InputLabel>
                <Flex gap={12}>
                  {!showWebsite ? (
                    <Button
                      size="small"
                      className="boxed"
                      type="blanco"
                      onClick={() => addOptional("website")}
                    >
                      <IconPlus /> Website
                    </Button>
                  ) : null}
                  {!showPhone ? (
                    <Button
                      size="small"
                      className="boxed"
                      type="blanco"
                      onClick={() => addOptional("phone")}
                    >
                      <IconPlus /> Phone Number
                    </Button>
                  ) : null}
                  {!showEmail ? (
                    <Button
                      size="small"
                      className="boxed"
                      type="blanco"
                      onClick={() => addOptional("email")}
                    >
                      <IconPlus /> Email
                    </Button>
                  ) : null}
                </Flex>
              </Flex>
            ) : null}

            {showEmail ? (
              <Flex vertical={true}>
                <InputLabel htmlFor="email" {...validation.email}>
                  Email <span>(optional)</span>
                </InputLabel>
                <FormInput
                  id="email"
                  value={form.email}
                  disabled={saving}
                  onChange={(e) => updateForm("email", e.target.value)}
                  placeholder="e.g. franklin@chase.com"
                  size="small"
                  {...validation.email}
                />
              </Flex>
            ) : null}
            {showPhone ? (
              <Flex vertical={true}>
                <InputLabel htmlFor="phone" {...validation.phone}>
                  Phone <span>(optional)</span>
                </InputLabel>
                <FormInput
                  id="phone"
                  value={form.phone}
                  disabled={saving}
                  onChange={(e) =>
                    updateForm("phone", FormatEXT(e.target.value))
                  }
                  placeholder="e.g. 555-532-3532 ext 1234"
                  size="small"
                  {...validation.phone}
                />
              </Flex>
            ) : null}
            {showWebsite ? (
              <Flex vertical={true}>
                <InputLabel htmlFor="website">
                  Website <span>(optional)</span>
                </InputLabel>
                <FormInput
                  id="website"
                  value={form.website}
                  disabled={saving}
                  onChange={(e) => updateForm("website", e.target.value)}
                  placeholder="e.g. https://chase.com"
                  size="small"
                />
              </Flex>
            ) : null}
          </Modal.Body>
          <Modal.Actions>
            <Button
              block
              className="boxed"
              type="secondary"
              onClick={() => setVisible(false)}
            >
              Cancel
            </Button>
            {props.editing ? (
              <Button
                block
                type="primary"
                className="boxed"
                disabled={!validation.allValid}
                onClick={saveForm}
                loading={saving}
              >
                Add Address
              </Button>
            ) : (
              <Button
                block
                type="primary"
                className="boxed"
                disabled={!validation.allValid}
                onClick={saveForm}
                loading={saving}
              >
                Save Address
              </Button>
            )}
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
}

export default ManageAddress;
