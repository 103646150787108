import { gql } from "@apollo/client";

export const getTaskTemplates = gql`
  query getTaskTemplates($type: String) {
    getTaskTemplates(type: $type) {
      id
      short_id
      title
      type
      subject
      instructions
      variables {
        variable
        mapping
      }
      is_html
      createdAt
    }
  }
`;

export const getTemplateVariables = gql`
  query getTemplateVariables($type: String!, $application_id: String!) {
    getTemplateVariables(type: $type, application_id: $application_id) {
      templates {
        id
        title
        subject
        instructions
        variables {
          variable
          mapping
        }
        is_html
      }
      customer {
        name
        first_name
        last_name
        co_name
        co_first_name
        co_last_name
        email
        phone
        dob
        ssn_last_4
        address
        address_line_two
        city
        state
        zip
      }
    }
  }
`;

export const getCustomerTasks = gql`
  query getCustomerTasks($application_id: String!) {
    getCustomerTasks(application_id: $application_id) {
      id
      title
      type
      instructions
      order
      start
      delay
      skipped
      completed
      note
      estimated_due_timestamp
      due_timestamp
      due_days
      is_due
      completed_timestamp
      assigned_user_id
      assigned_team_id
      template_id
      template {
        title
        type
        instructions
      }
      completed_user {
        id
        name
        email
      }
      assigned_user {
        id
        name
        email
      }
      assigned_team {
        id
        name
      }
    }
  }
`;

export const getMyTasksCount = gql`
  query {
    getMyTasks {
      todo {
        id
        application {
          id
          status
        }
      }
    }
  }
`;

export const searchTasks = gql`
  query {
    getMyTasks {
      todo {
        id
        title
        type
        application {
          id
          status
          first_name
          last_name
        }
      }
    }
  }
`;

export const getMyTasks = gql`
  query getMyTasks($type: String) {
    getMyTasks(type: $type) {
      todo {
        id
        title
        type
        instructions
        order
        start
        delay
        skipped
        completed
        note
        estimated_due_timestamp
        due_timestamp
        due_days
        is_due
        completed_timestamp
        assigned_user_id
        assigned_team_id
        template_id
        template {
          title
          type
          instructions
        }
        application {
          id
          short_id
          status
          first_name
          last_name
          phone
          email
        }
      }
      done {
        id
        title
        type
        instructions
        order
        start
        delay
        skipped
        completed
        note
        estimated_due_timestamp
        due_timestamp
        due_days
        is_due
        completed_timestamp
        assigned_user_id
        assigned_team_id
        template_id
        application {
          id
          short_id
          first_name
          last_name
          phone
          email
        }
      }
    }
  }
`;

export const adminTaskStats = gql`
  query adminTaskStats(
    $search: String
    $date: String
    $type: String
    $status: String
    $assignedUser: String
    $assignedTeam: String
    $taskType: String
  ) {
    adminTaskStats(
      search: $search
      date: $date
      type: $type
      status: $status
      assignedUser: $assignedUser
      assignedTeam: $assignedTeam
      taskType: $taskType
    ) {
      due_now
      upcoming
      overdue
      completed
      skipped
      call
      general
      text
      email
      mail
    }
  }
`;

export const adminTasks = gql`
  query adminTasks(
    $id: ID
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $search: String
    $date: String
    $type: String
    $status: String
    $assignedUser: String
    $assignedTeam: String
    $taskType: String
  ) {
    adminTasks(
      id: $id
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
      date: $date
      type: $type
      status: $status
      assignedUser: $assignedUser
      assignedTeam: $assignedTeam
      taskType: $taskType
    ) {
      data {
        id
        short_id
        title
        type
        instructions
        order
        start
        delay
        skipped
        completed
        note
        estimated_due_timestamp
        due_timestamp
        due_days
        is_due
        completed_timestamp
        assigned_user_id
        assigned_team_id
        template_id
        template {
          title
          type
          instructions
        }
        completed_user {
          id
          name
          email
        }
        assigned_user {
          id
          name
          email
        }
        assigned_team {
          id
          name
        }
      }
      total
      pageTotal
    }
  }
`;
