import React, { useState } from "react";
import { useTags } from "_contexts/useTags";
import { CustomTag, Checkbox, Select } from "_styleguide";
import "./FilterTags.scss";

function FilterTags(props) {
  const { tags, categories } = useTags();
  const { value, onChange } = props;
  const [open, setOpen] = useState(false);

  const toggleSelected = (val) => {
    if (val === "all") {
      onChange([]);
      setOpen(false);

      return;
    }
    setOpen(true);

    let list = [...value];
    if (list.includes(val)) {
      list = list.filter((l) => l !== val);
    } else {
      list.push(val);
    }
    onChange(list);
  };

  let fakeValue = value?.length <= 0 ? undefined : `Tags (${value.length})`;
  if (value?.length === 1) {
    fakeValue = value[0];
  }

  const handleDropdownVisibleChange = (visible) => {
    if (visible) setOpen(visible);
  };

  const uncategorized = tags?.filter((t) => !t.tag_category);

  return (
    <Select
      value={fakeValue}
      onChange={(val) => {
        setOpen(true);
      }}
      dropdownStyle={{ width: 240 }}
      className="pp-filter-tags"
      popupClassName="pp-filter-tags-dropdown"
      placeholder="All tags"
      open={open}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      onBlur={() => setOpen(false)}
      onSelect={(val) => toggleSelected(val)}
    >
      <Select.Option value="all">All Tags</Select.Option>
      {categories?.map((cat) => {
        const actualTags = tags?.filter((t) => t.tag_category === cat.name);
        return actualTags?.length > 0 ? (
          <Select.OptGroup
            key={`tfilt-${cat.id}`}
            label={cat.name.toUpperCase() + ` (${actualTags.length})`}
          >
            {actualTags.map((tagData) => (
              <Select.Option key={tagData.id} value={tagData.key}>
                <Checkbox
                  checked={value?.includes(tagData.key)}
                  onChange={() => toggleSelected(tagData.key)}
                />
                <CustomTag
                  backgroundColor={tagData.background_color}
                  fontColor={tagData.font_color}
                >
                  {tagData.description || "---"}
                </CustomTag>
              </Select.Option>
            ))}
          </Select.OptGroup>
        ) : null;
      })}

      {uncategorized?.length > 0 ? (
        <Select.OptGroup label="Uncategorized">
          {uncategorized.map((tagData) => (
            <Select.Option key={tagData.id} value={tagData.key}>
              <Checkbox
                checked={value?.includes(tagData.key)}
                onChange={() => toggleSelected(tagData.key)}
              />
              <CustomTag
                backgroundColor={tagData.background_color}
                fontColor={tagData.font_color}
              >
                {tagData.description || "---"}
              </CustomTag>
            </Select.Option>
          ))}
        </Select.OptGroup>
      ) : null}
    </Select>
  );
}

export default FilterTags;
