import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { myUsers } from "App/Admin/_graphql/queries/user";
import { getTeams } from "App/Admin/_graphql/queries/team";
import { Select } from "antd";
import { TeamAvatar, UserAvatar } from "_styleguide";
import "./SelectUsers.scss";

function SelectUsers(props) {
  const client = useApolloClient();
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const { assignedUser, assignedTeam, updateAssigned } = props;

  const fetchUsers = async () => {
    const queryData = await client.query({
      query: myUsers,
    });
    setUsers(queryData?.data?.myUsers || []);
  };
  const fetchTeams = async () => {
    const queryData = await client.query({
      query: getTeams,
    });
    setTeams(queryData?.data?.getTeams || []);
  };

  const changeAssigned = (assignedId) => {
    const userData = users?.find((t) => t.id === assignedId);
    const teamData = teams?.find((t) => t.id === assignedId);
    if (!userData && !teamData) {
      updateAssigned({ assignedUser: null, assignedTeam: null });
    } else if (teamData) {
      updateAssigned({ assignedUser: null, assignedTeam: assignedId });
    } else {
      updateAssigned({ assignedUser: assignedId, assignedTeam: null });
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      value={assignedUser || assignedTeam || "all"}
      onChange={changeAssigned}
      className="filter-select select-user-filter"
      popupClassName="pp-select-dropdown ppsd-task"
      placeholder="All Users"
      dropdownStyle={{ width: 240 }}
    >
      <Select.Option value="all">All Users</Select.Option>
      {teams?.map((t) => (
        <Select.Option key={t.id} value={t.id}>
          <TeamAvatar name={t.name} />
          {t.name}&nbsp;{`(${t.user_count})`}
        </Select.Option>
      ))}
      {users?.map((u) => (
        <Select.Option key={u.id} value={u.id}>
          <UserAvatar name={u.name} size="small" />
          {u.name}
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectUsers;
