import React, { useState, useEffect } from "react";
import { Search, Table } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { adminPayments } from "App/Admin/_graphql/queries/payment";
import { getColumns } from "./_data/Columns";
import CreatePayment from "./_modals/CreatePayment/CreatePayment";
import EditPayment from "./_modals/EditPayment/EditPayment";
import { Button, Modal, message } from "@centrate-io/barn";
import { cancelPayment } from "_graphql/mutations/payment";
import { getErrors } from "_helpers/api";
import "./Payments.scss";

const defaultParams = {
  results: 10,
  page: 1,
  sortField: "next_attempt",
  sortOrder: "ascend",
};

function Payments(props) {
  const client = useApolloClient();
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    pagination: { pageSize: 10, showSizeChanger: false },
  });
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [editPaymentModal, setEditPaymentModal] = useState(false);
  const [createPaymentModal, setCreatePaymentModal] = useState(false);

  // Load Payments
  const fetchPayments = async (params = defaultParams) => {
    const queryData = await client.query({
      variables: {
        ...params,
        id: props.applicationId,
        search: search,
      },
      query: adminPayments,
    });
    const pagination = { ...state.pagination };
    pagination.total = queryData?.data?.adminPayments?.pageTotal;
    pagination.totalAll = queryData?.data?.adminPayments?.total;
    setPage(params?.page || 1);
    setState({
      loading: false,
      data: queryData?.data?.adminPayments?.data || [],
      pagination: pagination,
    });
  };

  const userRole = props?.user?.role || "user";
  const partner = props?.user?.parent_company;

  const stopPayment = async (payment_id) => {
    Modal.confirm({
      title: "Are you sure you want cancel this payment?",
      okText: "Cancel Payment",
      content: (
        <span>
          <b>WARNING</b>: This <b>CANNOT BE UNDONE</b>, please proceed with
          caution when cancelling a payment.
        </span>
      ),
      onOk: async () => {
        try {
          await client.mutate({
            variables: { payment_id },
            mutation: cancelPayment,
          });
          message.success(`Payment successfully cancelled`);
          fetchPayments();
        } catch (err) {
          message.error(
            getErrors(err) || "Error cancelling payment, try again",
          );
        }
      },
    });
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const pager = { ...state.pagination };
    pager.current = pagination.current;
    setState({ pagination: pager });
    fetchPayments({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      search: search,
    });
  };

  useEffect(() => {
    fetchPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startResults = (page - 1) * defaultParams?.results + 1;
  const endResults = startResults + state?.data?.length - 1;

  const columns = getColumns(
    setEditPaymentModal,
    stopPayment,
    userRole,
    partner,
    props.readOnly,
  );

  return (
    <div className="admin-application-tradelines">
      {editPaymentModal ? (
        <EditPayment
          reload={fetchPayments}
          payment={editPaymentModal}
          removeModal={() => setEditPaymentModal(false)}
        />
      ) : null}
      {createPaymentModal ? (
        <CreatePayment
          applicationId={props.applicationId}
          reload={fetchPayments}
          removeModal={() => setCreatePaymentModal(false)}
        />
      ) : null}
      <div className="manage-search">
        <div className="ms-actions">
          <Search
            placeholder="Search payments..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onSearch={() => {
              fetchPayments({ ...defaultParams, search });
            }}
            style={{ width: 304 }}
          />
        </div>
        <div className="ms-filters">
          {userRole !== "user" && !props.readOnly ? (
            <Button
              type="primary"
              size="small"
              onClick={() => setCreatePaymentModal(true)}
            >
              +&nbsp;&nbsp;Add Payment
            </Button>
          ) : null}
        </div>
      </div>
      <div className="admin-table application-table">
        <Table
          columns={columns}
          loading={state.loading}
          pagination={state.pagination}
          onChange={handleTableChange}
          rowKey={(record) => record.id}
          dataSource={state?.data || []}
          showSorterTooltip={false}
          scroll={{
            y: "calc(100vh - 276px - 40px)",
            x: "max-content",
          }}
          locale={{
            emptyText: state.loading
              ? "Loading payments..."
              : "No payments found",
          }}
        />
      </div>
      {state?.data?.length > 0 && (
        <div className="admin-pagination">
          Showing {startResults}-{endResults} of {state?.pagination?.total || 0}{" "}
        </div>
      )}
    </div>
  );
}

export default Payments;
