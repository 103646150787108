import { gql } from "@apollo/client";

export const getTrigger = gql`
  query getTrigger($trigger_id: ID) {
    getTrigger(trigger_id: $trigger_id) {
      name
      is_active
      tags
      trigger_key
      trigger_ref_id
      trigger_multiple
      actions {
        action_key
        action_ref_id
        action_options {
          name
          value
        }
      }
    }
  }
`;

export const getTriggers = gql`
  query {
    getTriggers {
      id
      short_id
      name
      is_active
      tags
      trigger_key
      trigger_ref_id
      trigger_multiple
      actions {
        action_key
        action_ref_id
        action_options {
          name
          value
        }
      }
      run_count
      success_count
      fail_count
      last_run
      createdAt
    }
  }
`;
