import { gql } from "@apollo/client";

export const adminAddressBooks = gql`
  query adminAddressBooks(
    $id: ID
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
    $search: String
  ) {
    adminAddressBooks(
      id: $id
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
    ) {
      data {
        id
        short_id

        name
        logo
        unique_ids

        address_name
        address
        address_line_two
        city
        state
        zip

        website
        phone
        email
        allow_mail

        total_debt
        createdAt
        updatedAt
      }
      total
      pageTotal
    }
  }
`;

export const getAddressBook = gql`
  query getAddressBook($address_book_id: ID) {
    getAddressBook(address_book_id: $address_book_id) {
      name
      short_id
      address_name
      address
      address_line_two
      city
      state
      zip
      website
      phone
      email
    }
  }
`;

export const getAddressBookContact = gql`
  query getAddressBookContact($address_book_contact_id: ID) {
    getAddressBookContact(address_book_contact_id: $address_book_contact_id) {
      name
      organization
      is_default
      address
      address_line_two
      city
      state
      zip
      website
      phone
      email
      address_book_id
    }
  }
`;

export const getAddressBookContacts = gql`
  query getAddressBookContacts($address_book_id: ID) {
    getAddressBookContacts(address_book_id: $address_book_id) {
      id
      short_id
      name
      organization
      is_default
      address
      address_line_two
      city
      state
      zip
      website
      phone
      email
      address_book_id
      createdAt
      updatedAt
    }
  }
`;
